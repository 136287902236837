// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  --secondary-container: #ff5172; /* Inactive background */
  --primary: #0ccd9f; /* Active background */
  font-size: 14px;
  position: relative;
  display: inline-block;
  width: 3em; /* Reduced width */
  height: 1.5em; /* Reduced height */
}

.switch input {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcf0ec; /* Inactive background */
  transition: 0.2s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.2em; /* Reduced size */
  width: 1.2em; /* Reduced size */
  border-radius: 20px;
  left: 0.15em;
  bottom: 0.15em;
  background-color: #ff5172; /* Inactive circle */
  transition: 0.4s;
}

input:checked + .slider::before {
  background-color: #0ccd9f; /* Active circle */
}

input:checked + .slider {
  background-color: #0ccd9f4d; /* Active background */
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
  transform: translateX(1.5em); /* Adjusted for reduced width */
}
`, "",{"version":3,"sources":["webpack://./src/css/ToggleSwitch.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B,EAAE,wBAAwB;EACxD,kBAAkB,EAAE,sBAAsB;EAC1C,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,UAAU,EAAE,kBAAkB;EAC9B,aAAa,EAAE,mBAAmB;AACpC;;AAEA;EACE,aAAa;EACb,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,yBAAyB,EAAE,wBAAwB;EACnD,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa,EAAE,iBAAiB;EAChC,YAAY,EAAE,iBAAiB;EAC/B,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,yBAAyB,EAAE,oBAAoB;EAC/C,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,kBAAkB;AAC/C;;AAEA;EACE,2BAA2B,EAAE,sBAAsB;AACrD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,4BAA4B,EAAE,+BAA+B;AAC/D","sourcesContent":[".switch {\n  --secondary-container: #ff5172; /* Inactive background */\n  --primary: #0ccd9f; /* Active background */\n  font-size: 14px;\n  position: relative;\n  display: inline-block;\n  width: 3em; /* Reduced width */\n  height: 1.5em; /* Reduced height */\n}\n\n.switch input {\n  display: none;\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #fcf0ec; /* Inactive background */\n  transition: 0.2s;\n  border-radius: 30px;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 1.2em; /* Reduced size */\n  width: 1.2em; /* Reduced size */\n  border-radius: 20px;\n  left: 0.15em;\n  bottom: 0.15em;\n  background-color: #ff5172; /* Inactive circle */\n  transition: 0.4s;\n}\n\ninput:checked + .slider::before {\n  background-color: #0ccd9f; /* Active circle */\n}\n\ninput:checked + .slider {\n  background-color: #0ccd9f4d; /* Active background */\n}\n\ninput:focus + .slider {\n  box-shadow: 0 0 1px var(--secondary-container);\n}\n\ninput:checked + .slider:before {\n  transform: translateX(1.5em); /* Adjusted for reduced width */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
