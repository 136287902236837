import React, { useState } from "react";
import TextInput from "../../reusable/TextInput";
import useAddCategory from "../../hooks/categories/useAddCategory";
import usePopUp from "../../context/PopUpState";

const AddCategory = ({ onCategoryAdded }) => {
  // New prop to handle when a category is added
  const [categoryName, setCategoryName] = useState("");
  const { showPopup } = usePopUp();
  const { addCategory, loading, error } = useAddCategory();

  const handleSave = async () => {
    if (categoryName.trim()) {
      const success = await addCategory(categoryName); // Call the hook to add category
      if (success) {
        onCategoryAdded(); // Notify parent component to refresh categories
        showPopup(<PopUp />);
      }
    }
  };

  return (
    <div className="">
      <div className="text-[#1A253D] text-[2.2em] font-medium">
        Add New Category
      </div>
      <p className="text-[#1A253DB2] text-[1.4em] mb-[2.3em]">
        Pulvinar hendrerit accumsan placerat dolor, semper auctor
      </p>
      <p className="text-[1.6em] text-[#1A253DB2] mb-2">Category</p>
      <TextInput
        placeholder="Enter category name"
        height="h-[3em]"
        width=""
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        required={true}
      />
      {error && <p className="text-red-500 mt-2">Error: {error}</p>}
      <button
        onClick={handleSave}
        className="mt-[2em] bg-[#49389D] text-[1.8em] font-light text-white px-7 py-2 rounded-lg"
        disabled={loading}
      >
        {loading ? "Saving..." : "Save"}
      </button>
    </div>
  );
};

export default AddCategory;

const PopUp = () => {
  const { closePopup } = usePopUp();
  return (
    <div className="py-[5em] w-[30%] flex flex-col justify-center items-center  bg-white rounded-[18px]">
      <p className=" text-[2.2em] font-medium">Category Added successfully!</p>
      <p className=" text-[1.6em] font-light text-[#1A253D99]">
        Category Added successfully!
      </p>

      <button
        onClick={closePopup}
        className="mt-14 text-[#49389D] text-[1.8em] py-2 px-6 rounded-lg border-[#49389D] border-2"
      >
        Close
      </button>
    </div>
  );
};
