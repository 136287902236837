import React from "react";
import btnPlus from "../assets/svgs/reusable/btnPlus.svg";
import PropTypes from "prop-types";

const AddButton = ({ text, width, onClick }) => {
  const buttonClass = `flex items-center px-[1.8em] py-[0.9em] justify-center bg-[#49389D] text-white font-[300] text-[1.8em]  rounded-[10px] border-none hover:scale-105 hover:font-[400] transition duration-300`;

  return (
    <button
      className={buttonClass}
      style={{
        width: width || "",
        height: "50px",
        boxShadow: "0px 0px 19px 0px #31E0F757",
      }}
      onClick={onClick}
    >
      <img src={btnPlus} className="mr-2 " alt="" />
      {text}
    </button>
  );
};

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default AddButton;
