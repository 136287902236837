import { useState, useEffect } from "react";
import useApi from "../useApi";
import { BASE_URL, ENDPOINT_GET_TEMPLATES } from "../../constants/APIConstants";
import { useMemo } from "react";
import useUpdateTemplateStatus from "./useUpdateTemplateStatus";

const useGetTemplates = () => {
  const { makeApiCall, loading } = useApi();
  const { updateTemplateStatus } = useUpdateTemplateStatus();
  const [templates, setTemplates] = useState([]);
  const [templatesInfo, setTemplatesInfo] = useState({});
  const [filter, setFilter] = useState(null);
  const [error, setError] = useState(null);

  // Fetch templates (you can replace this with an API call)
  const fetchTemplates = async () => {
    try {
      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_GET_TEMPLATES}`,
        params: { ...filter },
      });

      const { data } = response?.data || {};

      setTemplates(data?.items);
      setTemplatesInfo({
        page: data?.pageOffset,
        pageSize: data?.pageEntries,
        totalEntries: data?.totalEntries,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const updateFilter = (page, pageSize, categoryId, cardId, cardName) => {
    setFilter({
      page,
      pageSize,
      categoryId:
        categoryId && categoryId.trim().length > 0 ? categoryId : null,
      cardId: cardId && cardId.trim().length > 0 ? cardId : null,
      cardName: cardName && cardName.trim().length > 0 ? cardName : null,
    });
  };

  const setPage = (page) => {
    setFilter((data) => ({
      ...data,
      page,
    }));
  };

  const setPageSize = (pageSize) => {
    setFilter((data) => ({
      ...data,
      pageSize,
    }));
  };

  useEffect(() => {
    if (filter) {
      fetchTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const templatesFormatted = useMemo(
    () =>
      templates.map((template) => ({
        imgUrl: `https://pbcdn.planetbeyondapps.com/resources/photofox/staging/card/thumbnail/${template?.thumbnailImageName}`,
        name: template?.name,
        cardId: template?.id,
        type: template?.premium ? "Premium" : "Free",
        price: `Rs. ${template?.cost}`,
        categories:
          template?.categories && Array.isArray(template?.categories)
            ? template?.categories.map((category) => category.name).join(", ")
            : "",
        active: template?.active,
        toggleActive: async () => {
          const result = await updateTemplateStatus(
            template.id,
            !template?.active
          );
          if (result) {
            setTemplates((prevTemplates) =>
              prevTemplates.map((templateTemp) =>
                templateTemp.id === template.id
                  ? { ...templateTemp, active: !templateTemp?.active }
                  : templateTemp
              )
            );
          }
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates]
  );

  return {
    templates,
    templatesInfo,
    templatesFormatted,
    fetchTemplates,
    page: templatesInfo?.page,
    setPage,
    pageSize: filter?.pageSize,
    setPageSize,
    updateFilter,
    loading,
    error,
  }; // Return both templates and the setter
};

export default useGetTemplates;
