import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../reusable/PageHeader";
import Dropdown from "../../reusable/Dropdown";
import ConfirmPopup from "../../reusable/ConfirmPopup";
import useFetchOrderDetails from "../../hooks/manage_orders/useFetchOrderDetails";
import useUpdateOrderStatus from "../../hooks/manage_orders/printer/useUpdateOrderStatus"; // Import your custom hook
import usePopUp from "../../context/PopUpState";
import { formatDateTime } from "../../functions/dateUtils";

function DedicatedOrderPrinter() {
  const { orderID } = useParams();
  const {
    orderDetails,
    loading: orderLoading,
    error: orderError,
  } = useFetchOrderDetails(orderID);
  const { showPopup } = usePopUp();
  const {
    updateOrderStatus,
    loading: updateLoading,
    error: updateError,
  } = useUpdateOrderStatus(); // Destructure the hook's properties
  const orderStatuses = ["Pending", "In Printing", "Ready For Pickup"];
  const defaultStatus = ""; // Default value for the dropdown
  const [orderStatus, setOrderStatus] = useState(defaultStatus);

  // Correct status codes
  const statusCodes = {
    Pending: 0,
    "In Printing": 1,
    "Ready For Pickup": 2,
  };

  // Determine the available options for the dropdown based on the current status
  const availableOptions =
    orderDetails?.status === statusCodes["In Printing"]
      ? ["Ready For Pickup"] // If current status is "In Printing", show only "Ready For Pickup"
      : orderDetails?.status === statusCodes["Ready For Pickup"]
      ? [] // If current status is "Ready For Pickup", show no options
      : ["In Printing", "Ready For Pickup"]; // Default options if status is neither

  if (orderLoading) return <p>Loading...</p>;
  if (orderError) return <p>Error: {orderError}</p>;

  const handleUpdateStatus = async () => {
    const statusCode = statusCodes[orderStatus];
    const success = await updateOrderStatus(orderID, statusCode);

    if (success) {
      alert("Order status updated successfully");
      window.location.reload();
    } else {
      alert("Failed to update order status");
    }
  };

  const triggerConfirmPopup = () => {
    showPopup(
      <ConfirmPopup
        title="Confirm Status Update"
        description="Are you sure you want to update the status of this order?"
        onConfirm={handleUpdateStatus}
        onCancel={() => console.log("Update canceled")}
      />
    );
  };

  return (
    <div>
      <PageHeader
        path={`Orders / Orders #${orderID}`}
        headerText={`Order #${orderID}`}
        actionComponent={
          <div className="bg-blue-100 text-[#3666eb] text-[1.4em] px-4 py-2 rounded-md">
            {orderStatuses[orderDetails?.status]}
          </div>
        }
      />

      {orderDetails ? (
        <div>
          <div className="py-3 px-5 font-[300] bg-gray-100 text-[1.4em] text-[#1A253D80] flex w-max items-center space-x-4 rounded-md mt-6 border-[1px]">
            <span>{orderDetails.orderItems.length} cards</span>
            <div className="h-[1.3em] bg-[#1a253d27] w-[0.15em]"></div>
            <span>{formatDateTime(orderDetails.date)}</span>
          </div>

          <div className="card-item px-6 pb-14 pt-8 bg-white rounded-lg shadow mb-12 mt-12">
            <p className="font-[600] text-[#1A253DE6] text-[2.4em] mb-4">
              Change Status
            </p>
            <Dropdown
              options={availableOptions} // Use filtered options here
              defaultOption="Change Status" // Provide the default placeholder text
              height="h-[2.6em]"
              width="w-[10em]"
              onChange={(value) => setOrderStatus(value)}
              value={orderStatus}
            />
            <button
              onClick={triggerConfirmPopup}
              className={`mt-6 text-white text-[1.2em] px-6 py-2 rounded-md ${
                orderStatus === defaultStatus || updateLoading
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-[#49389D] hover:bg-[#372A7D]"
              }`}
              disabled={orderStatus === defaultStatus || updateLoading}
            >
              {updateLoading ? "Updating..." : "Update Status"}
            </button>
            {updateError && <p className="text-red-500 mt-2">{updateError}</p>}
          </div>

          <div className="mt-8">
            {orderDetails?.orderItems.map((card) => (
              <CardItem key={card.id} card={card} />
            ))}
          </div>
        </div>
      ) : (
        <p>No order details available.</p>
      )}
    </div>
  );
}

// CardItem Component defined within the same file
const CardItem = ({ card }) => {
  return (
    <div className="card-item p-6 bg-white rounded-lg shadow mb-8">
      {/* Card Name */}
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-[500] text-[#1A253D] text-[2em]">
          {card.orderedCardName}
        </h3>
        <span className="text-[1.6em] text-blue-500 cursor-pointer">
          Card ID: #{card.id}
        </span>
      </div>
      <div className="h-[0.1em] bg-[#e5e5e5b9] my-8"></div>

      {/* Card Text Details */}
      <h3 className="font-[400] text-[#1A253D] text-[2em]  mt-6 mb-4">
        Card Details
      </h3>
      <div className="text-[1.6em] text-[#1A253D99] font-[300] border-[#ededed80] border-[1px]  bg-[#FCFCFC] p-4 rounded-md mb-12 space-y-6">
        <p className="font-[500] text-[#1A253DB3]">Text:</p>
        <p>{card.customizedCardDetails.greetingsText}</p>
        <p>{card.customizedCardDetails.messageText}</p>
        <p>{card.customizedCardDetails.closingText}</p>
      </div>

      {/* Font and Color */}
      <div className="flex space-x-4">
        <div className="text-[1.6em] text-[#1A253D] w-[16em] rounded-[12px] bg-[#FCFCFC] border-[#edededE6] border-[1px] py-[1.5em] px-[2.5em]  ">
          <p>Font</p>
          <p className="font-semibold">{card.customizedCardDetails.textFont}</p>
        </div>
        <div className="text-[1.6em] text-[#1A253D] w-[16em] rounded-[12px] bg-[#FCFCFC] border-[#edededE6] border-[1px] py-[1.5em] px-[2.5em]">
          <p className="font-semibold">Color</p>
          <div className="flex items-center space-x-2 mt-2">
            <div
              className="w-6 h-6 rounded-full"
              style={{
                backgroundColor: card?.customizedCardDetails?.textColor,
              }}
            ></div>
            <p className="text-[#1A253D]">
              {card?.customizedCardDetails?.textColor}
            </p>
          </div>
        </div>
      </div>

      {/* Card Images */}
      <div className="mt-8">
        <h3 className="font-[400] text-[#1A253D] text-[2em]  mt-6 mb-4">
          Cards Images
        </h3>
        <div className="flex space-x-4">
          <div className="bg-[#ddb4f0] w-[23%] flex justify-center items-center h-[40em] rounded-lg">
            <img
              src={`https://pbcdn.planetbeyondapps.com/resources/photofox/staging/card/customized_main_image/${card.customizedCardDetails.customizedMainImageName}`}
              alt="MainCardImage"
              className="w-[200px] bg-white h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="bg-[#ddb4f0] w-[23%] flex justify-center items-center h-[40em] rounded-lg">
            <img
              src={`https://pbcdn.planetbeyondapps.com/resources/photofox/staging/card/customized_inner_image/${card.customizedCardDetails.customizedInnerImageName}`}
              alt="InnerCardImage"
              className="w-[200px] h-auto rounded-lg shadow-lg bg-white "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DedicatedOrderPrinter;
