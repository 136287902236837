import React from "react";

function Header() {
  return (
    <div
      className="h-[5em] "
      style={{ boxShadow: "0px 4px 9.8px 0px rgba(0, 0, 0, 0.07)" }}
    ></div>
  );
}

export default Header;
