import React, { useMemo } from "react";
import CustomTable from "../../reusable/CustomTable";
import { formatDate } from "../../functions/dateUtils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/RouteConstants";
import actions from "../../mock/actions.json";
import { useRole } from "../../context/RoleContext";
const ManageInvoiceComponent = ({
  invoices,
  maxPages,
  totalEntries,
  page,
  setPage,
  setPageSize,
  pageSize,
  tableHeader = true,
  tableHeaderName = "Invoices",
}) => {
  const navigate = useNavigate();
  const { isActionAllowed } = useRole();
  // status labels
  const statusLabels = {
    "-1": "All",
    0: "Pending",
    1: "Paid",
    2: "Cancelled",
  };

  // Define headers for the table
  const headers = [
    { headerText: "Invoice ID", dataKey: "id" },
    { headerText: "Printer", dataKey: "printer" },
    { headerText: "Contact", dataKey: "printerContact" },
    { headerText: "Email", dataKey: "printerEmail" },
    { headerText: "Total Amount", dataKey: "totalAmount" },
    { headerText: "Status", dataKey: "status" },
    { headerText: "Creation Date", dataKey: "creationTimestamp" },
  ];

  // Format the data for rendering in the table
  const tableFormattedData = useMemo(
    () =>
      Array.isArray(invoices) &&
      invoices.map((dataItem) => ({
        ...dataItem,
        id: (
          <button
            className="text-blue-500 cursor-pointer min-w-14"
            onClick={() => {
              if (isActionAllowed(actions.EditInvoiceStatus)) {
                navigate(PATHS.DEDICATED_INVOICE.replace(":id", dataItem?.id), {
                  state: { ...dataItem },
                });
              } else {
                navigate(PATHS.UNAUTHORIZED);
              }
            }}
          >
            {dataItem?.id}
          </button>
        ),
        printer: <span>{dataItem?.printer}</span>,
        printerContact: <span>+ {dataItem?.printerContact}</span>,
        printerEmail: <span>{dataItem?.printerEmail}</span>,
        totalAmount: `${dataItem?.totalAmount.toFixed(2)}`,
        status: <span>{statusLabels[dataItem?.status]}</span>,
        creationTimestamp: formatDate(dataItem?.creationTimestamp),
      })),
    [invoices]
  );

  return (
    <div className="">
      {tableHeader && (
        <div className="flex items-center justify-between px-[2.1em] py-[1.7em]">
          <div className="text-[#1A1E1A] text-[1.8em] font-[500]">
            {tableHeaderName}
          </div>
        </div>
      )}
      <div>
        <CustomTable
          headers={headers}
          data={tableFormattedData}
          footer={true}
          totalPages={maxPages}
          totalEntries={totalEntries}
          rowsPerPage={pageSize}
          setRowsPerPage={setPageSize}
          currentPage={+page + 1}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default ManageInvoiceComponent;
