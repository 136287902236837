import React from "react";
import PropTypes from "prop-types";

const RadioButton = ({
  label,
  name,
  value,
  checked,
  onChange,
  required = false,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="cursor-pointer"
        required={required} // Apply required attribute
      />
      <label
        htmlFor={value}
        className="cursor-pointer text-[1.4em] text-[#1A253D99]"
      >
        {label}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool, // Add required as prop type
};

export default RadioButton;
