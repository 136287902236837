// StatCard.jsx
import React from "react";
import "../css/StatCard.css"; // Import the CSS file where Tailwind @apply will be used
import chart from "../assets/svgs/dashboard/chart.svg";
const StatCard = ({ title, value }) => {
  return (
    <div className="stat-card ">
      <div>
        <div className="stat-card-value">{value}</div>
        <div className="stat-card-title">{title}</div>
      </div>

      <img src={chart} alt="" />
    </div>
  );
};

export default StatCard;
