import React from "react";
import actions from "../../mock/actions.json";
import {useRole} from "../../context/RoleContext";

const StatusTabs = ({selectedTab, onSelectTab}) => {
    const {isActionAllowed} = useRole();
    // Define the statuses array based on different conditions
    let statuses = [];

    if (isActionAllowed(actions.adminOrderStatuses)) {
        statuses = [
            {name: "All", statusCode: -1, label: "All"},
            {
                name: "Queued for Printing",
                statusCode: 0,
                label: "Queued for Printing",
            },
            {name: "In Printing", statusCode: 1, label: "In Printing"},
            {name: "Ready for Pickup", statusCode: 2, label: "Ready for Pickup"},
            {
                name: "Ready for Shipment",
                statusCode: 3,
                label: "Ready for Shipment",
            },
            {
                name: "Payment Failed",
                statusCode: 4,
                label: "Payment Failed",
            },
        ];
    } else if (isActionAllowed(actions.printerOrderStatuses)) {
        statuses = [
            {name: "All", statusCode: -1, label: "All"},
            {name: "Pending", statusCode: 0, label: "Pending"},
            {name: "In Printing", statusCode: 1, label: "In Printing"},
            {name: "Ready For Pickup", statusCode: 2, label: "Ready For Pickup"},
        ];
    }

    return (
        <div className="flex gap-4">
            {statuses.map((status) => (
                <div className="flex items-center" key={status.statusCode}>
                    <button
                        className={`px-2 pb-2 ${
                            selectedTab === status.statusCode
                                ? "text-[#4A1992] font-[500] text-[1.4em] "
                                : "text-[#1A253D80] font-[300] text-[1.4em] hover:text-purple-700"
                        }`}
                        onClick={() => onSelectTab(status.statusCode)}
                    >
                        {status.label}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default StatusTabs;
