import React, { useEffect, useState } from "react";
import CategoriesComponent from "../components/categories/CategoriesComponent";
import useFetchCategories from "../hooks/categories/useFetchCategories";
import AddButton from "../reusable/AddButton";
import actions from "../mock/actions.json";
import { useRole } from "../context/RoleContext";
import PageHeader from "../reusable/PageHeader";
import OffCanvas from "../reusable/OffCanvas";
import AddCategory from "../components/categories/AddCategory";

function Categories() {
  const { isActionAllowed } = useRole();
  const { categories, categoriesInfo, loading, error, updateFilter } =
    useFetchCategories();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false); // State to control off-canvas visibility

  useEffect(() => {
    updateFilter(page, pageSize);
    // eslint-disable-next-line
  }, [page, pageSize]);

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Error state
  }

  const handleClick = () => {
    setIsOffCanvasOpen(true); // Open the off-canvas
  };

  const handleClose = () => {
    setIsOffCanvasOpen(false); // Close the off-canvas
  };

  const handleCategoryAdded = () => {
    // Refetch categories after adding a new one
    updateFilter(page, pageSize);
    handleClose(); // Close the off-canvas
  };

  return (
    <div>
      <PageHeader
        path={"/ Categories"}
        headerText={"Categories"}
        actionComponent={
          <>
            {isActionAllowed(actions.ManageCategories) && (
              <div>
                <AddButton text="New Category" width="" onClick={handleClick} />
              </div>
            )}
          </>
        }
      />

      <CategoriesComponent
        categories={categories}
        maxPages={Math.ceil(categoriesInfo?.totalEntries / pageSize)}
        totalEntries={categoriesInfo?.totalEntries}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        tableHeaderName={"Categories"} // Table header name
      />

      <OffCanvas isOpen={isOffCanvasOpen} onClose={handleClose}>
        <AddCategory onCategoryAdded={handleCategoryAdded} />{" "}
        {/* Pass the function here */}
      </OffCanvas>
    </div>
  );
}

export default Categories;
