// src/components/MainLayout.jsx
import React from "react";

import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";

const MainLayout = () => {
  return (
    <div className="flex min-h-svh">
      <Sidebar />
      <div className="w-full overflow-auto ">
        <Header />
        <div className="px-[6.8em] py-[2em]">
          <Outlet /> {/* Render child routes here */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
