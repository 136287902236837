import { logoutUser, saveToken } from "../../functions/tokenFunctions";
import { BASE_URL } from "../../constants/APIConstants";
import axios from "axios";

const useRefreshToken = () => {
  const refreshToken = async () => {
    const refreshTokenToken = sessionStorage.getItem("refreshToken");
    const url = `${BASE_URL}${"/api/user/v1/refresh-token"}`;
    // console.log(url);
    try {
      const response = await axios.post(url, {
        headers: { Authorization: `Bearer ${refreshTokenToken}` },
      });
      const { data } = response?.data;
      // console.log(data);
      saveToken(data?.tokens);
      return data.token;
    } catch (error) {
      console.log(error);
      if (+error?.response?.status === 401) {
        alert("Session Expired. Please Log in again");
        logoutUser();
      }
    }
  };
  return refreshToken;
};

export default useRefreshToken;
