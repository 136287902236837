import { useState, useEffect } from "react";
// import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_CATEGORIES,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useFetchCategories = () => {
  const { makeApiCall, loading } = useApi();
  const [categories, setCategories] = useState([]);
  const [categoriesInfo, setCategoriesInfo] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(null);

  const updateFilter = (page, size) => {
    setFilter({
      page: page,
      pageSize: size,
    });
  };

  useEffect(() => {
    const fetchCategories = async (page, pageSize) => {
      try {
        // Retrieve token if necessary
        // const authToken = sessionStorage.getItem("authToken");
        // if (!authToken) {
        //   throw new Error("No auth token found. Please log in again.");
        // }

        // // Axios request
        // const response = await axios.get(
        //   `${BASE_URL}${ENDPOINT_GET_CATEGORIES}?page=${page}&pageSize=${pageSize}`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${authToken}`,
        //     },
        //   }
        // );

        const response = await makeApiCall({
          url: `${BASE_URL}${ENDPOINT_GET_CATEGORIES}`,
          params: {
            page,
            pageSize,
          },
        });

        const { data } = response?.data;

        if (response.data.success) {
          setCategories(data?.items); // Update categories state
          setCategoriesInfo({ totalEntries: data?.totalEntries });
        } else {
          throw new Error("Failed to retrieve categories");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    if (filter) {
      fetchCategories(filter.page, filter.pageSize); // Fetch categories whenever filter changes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { categories, categoriesInfo, loading, error, filter, updateFilter };
};

export default useFetchCategories;
