import React, { useEffect, useState } from "react";
import PageHeader from "../reusable/PageHeader";
import AddButton from "../reusable/AddButton";
import actions from "../mock/actions.json";
import { useRole } from "../context/RoleContext";
import TextInput from "../reusable/TextInput";
import TemplatesComponent from "../components/templattes/TemplatesComponent";
import Dropdown from "../reusable/Dropdown";
import OffCanvas from "../reusable/OffCanvas";
import AddTemplate from "../components/templattes/AddTemplate"; // Import the AddTemplate form
import useGetTemplates from "../hooks/templates/useGetTemplates"; // Hook to get existing templates
import useFetchCategories from "../hooks/categories/useFetchCategories";
import usePopUp from "../context/PopUpState";
import AddSuccessPopup from "../components/templattes/AddSuccessPopup";

function Templates() {
  const { showPopup } = usePopUp();
  const { isActionAllowed } = useRole();
  const [userName, setUserName] = useState("");
  const [cardId, setCardId] = useState("");
  const [selectedGreeting, setSelectedGreeting] = useState(""); // State to store selected greeting
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false); // State to control off-canvas visibility
  const {
    templatesFormatted,
    updateFilter: fetchTemplates,
    page,
    setPage,
    pageSize,
    setPageSize,
    templatesInfo,
  } = useGetTemplates(); // Fetch existing templates
  const { categories, updateFilter: fetchCategories } = useFetchCategories();

  useEffect(() => {
    fetchCategories(0, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTemplates(0, 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setIsOffCanvasOpen(true); // Open the off-canvas
  };

  const handleClose = () => {
    setIsOffCanvasOpen(false); // Close the off-canvas
  };

  // Function to add a new template to the table
  const handleAddTemplate = () => {
    setIsOffCanvasOpen(false); // Close off-canvas after save

    showPopup(<AddSuccessPopup />);
    fetchTemplates();

    // Optionally scroll to the bottom of the table to see the new template
    const tableElement = document.querySelector("#templateTable");
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Handle applying filters when the "Search" button is clicked
  const handleSearch = () => {
    fetchTemplates(0, pageSize, selectedGreeting, cardId, userName);
  };

  // Handle clearing filters when the "Clear" button is clicked
  const handleClear = () => {
    setUserName(""); // Clear User Name
    setCardId(""); // Clear Card ID
    setSelectedGreeting(""); // Clear Greeting
    fetchTemplates(0, pageSize);
  };

  return (
    <div>
      <PageHeader
        path={"/ Templates"}
        headerText={"Templates"}
        actionComponent={
          <>
            {isActionAllowed(actions.CreateTemplate) && (
              <div>
                <AddButton text="New Template" width="" onClick={handleClick} />
              </div>
            )}
          </>
        }
      />

      {/* Search Inputs */}
      <div className="flex items-center justify-between mt-14">
        <div className="gap-2 flex items-center">
          <TextInput
            placeholder="Card Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextInput
            placeholder="Card ID"
            value={cardId}
            onChange={(e) => setCardId(e.target.value)}
          />

          {/* Dropdown for Greeting */}
          <Dropdown
            options={categories.map((cat) => ({ ...cat, value: cat.id }))} // Use categories fetched by the hook
            defaultOption="Category" // Provide the default placeholder
            height="h-[2.6em]"
            width="w-[17em]"
            onChange={(value) => setSelectedGreeting(value)}
            value={selectedGreeting} // Ensure controlled input
          />
        </div>

        {/* Buttons for Search and Clear */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handleSearch} // Trigger search on button click
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Search
          </button>
          <button
            onClick={handleClear} // Clear filters on button click
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Clear
          </button>
        </div>
      </div>

      {/* Template Table */}
      <div id="templateTable">
        <TemplatesComponent
          templates={templatesFormatted} // Pass the current templates list
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalEntries={templatesInfo?.totalEntries}
        />
      </div>

      {/* OffCanvas for Adding New Template */}
      <OffCanvas isOpen={isOffCanvasOpen} onClose={handleClose}>
        <AddTemplate onSave={handleAddTemplate} />
      </OffCanvas>
    </div>
  );
}

export default Templates;
