import React, { useState } from "react";

const UploadImage = ({ onImageUpload }) => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Show the image preview
      onImageUpload(e); // Call the parent's handler to store the image file
    }
  };

  return (
    <div className="mt-[3.4em]">
      <label className="block mb-[1em] text-[1.9em] text-[#1A253DCC] font-medium">
        Upload Image
      </label>

      <div
        className={`relative w-[150px] h-[150px] border-[1px] border-dashed border-[#1A253D80] rounded-md cursor-pointer flex items-center justify-center ${
          imagePreview ? "" : "hover:border-gray-500"
        }`}
      >
        {!imagePreview ? (
          <span className="text-gray-400 text-4xl">+</span>
        ) : (
          <img
            src={imagePreview}
            alt="Uploaded"
            className="object-cover w-full h-full rounded-md"
          />
        )}

        <input
          type="file"
          accept="image/*"
          className="absolute inset-0 opacity-0 cursor-pointer"
          onChange={handleImageChange}
        />
      </div>
    </div>
  );
};

export default UploadImage;
