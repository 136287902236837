// src/components/SimpleLayout.jsx
import React from "react";
import { Outlet } from "react-router-dom";

const SimpleLayout = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <Outlet /> {/* Render child routes here */}
    </div>
  );
};

export default SimpleLayout;
