import React, { useEffect, useState } from "react";
import PageHeader from "../reusable/PageHeader";
import ManageOrderComponent from "../components/manage_order/ManageOrderComponent";
import StatusTabs from "../components/manage_order/StatusTabs";
import useFetchOrders from "../hooks/manage_orders/useFetchOrders";

function ManageOrders({ statusHeader = true }) {
  const [selectedTab, setSelectedTab] = useState(-1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { orders, ordersInfo, loading, error, updateFilter } = useFetchOrders();
  useEffect(() => {
    updateFilter(selectedTab, page, pageSize);
    // eslint-disable-next-line
  }, [selectedTab, page, pageSize]);
  // console.log(ordersInfo);
  return (
    <div>
      <PageHeader path={"/ Manage Orders"} headerText={"Manage Orders"} />

      <div className="flex items-center justify-end mt-14">
        {statusHeader && (
          <StatusTabs selectedTab={selectedTab} onSelectTab={setSelectedTab} />
        )}
      </div>

      <div className="">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <ManageOrderComponent
            orders={orders}
            maxPages={Math.ceil(ordersInfo?.totalEntries / pageSize)}
            totalEntries={ordersInfo?.totalEntries}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </div>
  );
}

export default ManageOrders;
