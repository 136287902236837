import React from "react";

const TextInput = ({
  label,
  placeholder,
  value,
  onChange,
  height = "h-10",
  width = "w-full",
  required = false, // New required prop
  type = "text"
}) => {
  return (
    <div className="">
      {label && (
        <label className="block mb-1 text-sm font-medium">{label}</label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required} // Apply required attribute
        className={`text-[1.4em] font-[300] text-[#1A253D80] px-4 py-2 border border-[1A253D4D] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 ${height} ${width}`}
      />
    </div>
  );
};

export default TextInput;
