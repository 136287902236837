// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* StatCard.css */
.stat-card {
  display: flex;
  min-height: 121px;
  min-width: 250px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background: linear-gradient(
    220.8deg,
    #ffefd3 4.69%,
    #e0e0ff 41.39%,
    #b4fff2 120.42%
  )
}

.stat-card-value {
  font-size: 2em;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(26 30 26 / var(--tw-text-opacity))
}

.stat-card-title {
  margin-top: 1em;
  font-size: 1.2em;
  --tw-text-opacity: 1;
  color: rgb(6 100 92 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/css/StatCard.css"],"names":[],"mappings":"AAAA,iBAAiB;AAEf;EAAA,aAAgH;EAAhH,iBAAgH;EAAhH,gBAAgH;EAAhH,mBAAgH;EAAhH,8BAAgH;EAAhH,sBAAgH;EAAhH,mBAAgH;EAAhH,oBAAgH;EAAhH,kBAAgH;EAAhH,qBAAgH;EAAhH,0CAAgH;EAAhH,uDAAgH;EAAhH,uGAAgH;EAChH;;;;;;AADgH;;AAUhH;EAAA,cAA2C;EAA3C,gBAA2C;EAA3C,oBAA2C;EAA3C;AAA2C;;AAI3C;EAAA,eAAuC;EAAvC,gBAAuC;EAAvC,oBAAuC;EAAvC;AAAuC","sourcesContent":["/* StatCard.css */\n.stat-card {\n  @apply rounded-xl px-[2.5em] py-[1.4em] shadow-sm flex items-center justify-between  min-w-[250px] min-h-[121px];\n  background: linear-gradient(\n    220.8deg,\n    #ffefd3 4.69%,\n    #e0e0ff 41.39%,\n    #b4fff2 120.42%\n  );\n}\n\n.stat-card-value {\n  @apply text-[2em] text-[#1A1E1A] font-[600];\n}\n\n.stat-card-title {\n  @apply text-[1.2em] text-[#06645C] mt-4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
