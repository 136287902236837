import { useState } from "react";
import {
  BASE_URL,
  ENDPOINT_ADD_CONSIGNMENT,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useAddConsignment = () => {
  const [error, setError] = useState(null);
  const { makeApiCall, loading } = useApi();

  const addConsignment = async (orderId, data) => {
    try {
      setError(null);

      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_ADD_CONSIGNMENT}${orderId}`, // Append orderId dynamically
        method: "PUT",
        data,
      });

      if (response.data.success) {
        return true;
      } else {
        throw new Error(response.data.message || "Failed to add consignment");
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
      console.error("Error adding consignment:", error);
      return false;
    }
  };

  return { addConsignment, loading, error };
};

export default useAddConsignment;
