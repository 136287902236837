import { BASE_URL, ENDPOINT_ADD_TEMPLATE } from "../../constants/APIConstants";
import useApi from "../useApi";

const useAddTemplate = () => {
  const { makeApiCall } = useApi();
  const addTemplate = async (templateData) => {
    try {
      // await axios.post(`${BASE_URL}${ENDPOINT_ADD_TEMPLATE}`, templateData);
      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_ADD_TEMPLATE}`,
        method: "POST",
        data: templateData,
      });
      return response.data.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return addTemplate;
};

export default useAddTemplate;
