import React, { useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get the orderId
import PageHeader from "../reusable/PageHeader";
import CardItem from "../reusable/CardItem";
import useFetchOrderDetails from "../hooks/manage_orders/useFetchOrderDetails";
import useAddConsignment from "../hooks/manage_orders/useAddConsignment"; // Import the hook for consignment API
import { formatDateTime } from "../functions/dateUtils";

function DedicatedOrder() {
  const { orderID } = useParams(); // Capture the orderId from the URL
  const { orderDetails, loading, error, fetchOrderDetails } =
    useFetchOrderDetails(orderID); // Fetch the order details from the hook
  const {
    addConsignment,
    loading: consignmentLoading,
    error: consignmentError,
  } = useAddConsignment(); // Use the consignment API hook

  const [editing, setEditing] = useState(false);
  const [consignmentNumbers, setConsignmentNumbers] = useState({}); // Store consignment numbers for each card

  const orderStatuses = [
    "Queued for Printing",
    "In Printing",
    "Ready for Pickup",
    "Ready for Shipment",
  ];

  const handleConsignmentChange = (cardId, value) => {
    setConsignmentNumbers((prev) => ({
      ...prev,
      [cardId]: value,
    }));
  };

  const handleSaveConsignments = async () => {
    const data = Object.entries(consignmentNumbers).map((entry) => {
      return {
        orderItemId: entry[0],
        consignmentNumber: entry[1],
      };
    });
    if (
      data.some(
        (entry) =>
          !entry.consignmentNumber || entry.consignmentNumber.length === 0
      )
    ) {
      alert("Error");
    } else {
      const result = await addConsignment(orderID, data); // Save each consignment number
      if (result) {
        fetchOrderDetails();
      }
    }
    // window.location.reload(); // Reload the page to fetch updated details
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      {/* Page Header */}
      <PageHeader
        path={`Orders / Orders #${orderID}`} // Dynamic order ID in the path
        headerText={`Order #${orderID}`} // Dynamic order ID in the header
        actionComponent={
          <div className="bg-blue-100 text-[#3666eb] text-[1.4em] px-4 py-2 rounded-md">
            {orderStatuses[orderDetails?.status]}
          </div>
        } // Status of the order
      />

      {orderDetails ? (
        <div>
          {/* Displaying Order Information */}
          <div className="py-3 px-5 font-[300] bg-gray-100 text-[1.4em] text-[#1A253D80] flex w-max items-center space-x-4 rounded-md mt-6 border-[1px]">
            <span>{orderDetails.orderItems.length} cards</span>
            <div className="h-[1.3em] bg-[#1a253d27] w-[0.15em]"></div>
            <span>{formatDateTime(orderDetails.date)}</span>
          </div>

          {/* Customer Contact Details */}
          <div className="card-item p-6 bg-white rounded-lg shadow mb-8 mt-10 pb-16">
            <h3 className="font-semibold mb-1 text-[#1A253D] text-[2.4em]">
              Customer Contact Details
            </h3>
            <p className="text-[1.5em] text-[#4471D7]">
              <a
                href={`mailto:${orderDetails?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {orderDetails?.email}
              </a>
            </p>
          </div>

          {/* Add Consignment IDs Button */}
          {orderDetails?.status === 2 && !editing && (
            <button
              onClick={() => setEditing(true)}
              className="bg-blue-500 text-white py-2 px-4 rounded-md"
            >
              Add Consignment IDs
            </button>
          )}

          {/* Card Items List */}
          <div className="mt-8">
            {orderDetails?.orderItems.map((card) => (
              <CardItem
                key={card.id}
                card={card}
                editing={orderDetails?.status === 2 && editing}
                consignmentNumber={consignmentNumbers[card.id] || ""}
                onConsignmentChange={(value) =>
                  handleConsignmentChange(card.id, value)
                }
              />
            ))}
          </div>

          {/* Save Consignment IDs Button */}
          {editing && (
            <button
              onClick={handleSaveConsignments}
              className="bg-green-500 text-white py-2 px-4 mt-4 rounded-md"
              disabled={consignmentLoading}
            >
              {consignmentLoading ? "Saving..." : "Save Consignment IDs"}
            </button>
          )}

          <div className="mt-6 ">
            <h3 className="font-semibold text-[#1A253D] text-[2em] mb-1">
              Transactions
            </h3>
          </div>
        </div>
      ) : (
        <p>No order details available.</p>
      )}
    </div>
  );
}

export default DedicatedOrder;
