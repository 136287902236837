import React from "react";
import usePopUp from "../context/PopUpState";

const ConfirmPopup = ({
  title = "Are you sure?",
  description = "Please confirm are you sure?",
  onConfirm,
  onCancel,
  confirmText = "Yes",
  cancelText = "No",
}) => {
  const { closePopup } = usePopUp();

  const handleConfirm = () => {
    onConfirm();
    closePopup();
  };

  const handleCancel = () => {
    onCancel();
    closePopup();
  };

  return (
    <div className="py-[5em] w-[30%] flex flex-col justify-center items-center bg-white rounded-[18px] shadow-lg">
      <p className="text-[2.2em] font-medium">{title}</p>
      <p className="text-[1.6em] font-light text-[#1A253D99]">{description}</p>
      <div className="flex mt-10 gap-4">
        <button
          onClick={handleConfirm}
          className="text-[#49389D] text-[1.8em] py-2 px-6 rounded-lg border-[#49389D] border-2 hover:bg-[#49389D] hover:text-white transition-colors"
        >
          {confirmText}
        </button>
        <button
          onClick={handleCancel}
          className="text-[#49389D] text-[1.8em] py-2 px-6 rounded-lg border-[#49389D] border-2 hover:bg-[#49389D] hover:text-white transition-colors"
        >
          {cancelText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmPopup;
