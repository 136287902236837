import React, { useMemo } from "react";
import CustomTable from "../../reusable/CustomTable";
// import more from "../../assets/svgs/tables/more.svg";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/RouteConstants";
import actions from "../../mock/actions.json";
import { useRole } from "../../context/RoleContext";
import { formatDate, formatDateTime } from "../../functions/dateUtils";
const ManageOrderComponent = ({
  orders,
  maxPages,
  totalEntries,
  page,
  setPage,
  setPageSize,
  pageSize,
  tableHeader = true,
  tableHeaderName = "Orders",
}) => {
  const navigate = useNavigate();
  const { isActionAllowed } = useRole();
  const statusLabels = useMemo(() => {
    if (isActionAllowed(actions.DedicatedOrder)) {
      return {
        0: "Queued for Printing",
        1: "In Printing",
        2: "Ready for Pickup",
        3: "Ready for Shipment",
        4: "Payment Failed",
      };
    } else if (isActionAllowed(actions.DedicatedOrderPrinter)) {
      return {
        0: "Queued for Printing",
        1: "In Printing",
        2: "Ready for Pickup",
      };
    } else {
      return {}; // Default to an empty object if no actions are allowed
    }
  }, [isActionAllowed, actions]);

  // Define headers for the table
  const headers = [
    { headerText: "Order ID", dataKey: "orderId" },
    { headerText: "Email", dataKey: "email" },
    { headerText: "Date", dataKey: "date" },
    { headerText: "Total Price", dataKey: "totalPrice" },
    { headerText: "Status", dataKey: "status" },
    // { headerText: "", dataKey: "more", colClass: "text-center" },
  ];

  // Format the sorted data for rendering in the table
  const tableFormattedData = useMemo(
    () =>
      Array.isArray(orders) &&
      orders.map((dataItem) => ({
        ...dataItem,
        orderId: (
          <button
            className="text-blue-500 cursor-pointer min-w-14"
            onClick={() => {
              if (isActionAllowed(actions.DedicatedOrderPrinter)) {
                navigate(
                  PATHS.DEDICATED_ORDER_PRINTER.replace(
                    ":orderID",
                    dataItem?.orderId
                  )
                );
              } else if (isActionAllowed(actions.DedicatedOrder)) {
                navigate(
                  PATHS.DEDICATED_ORDER.replace(":orderID", dataItem?.orderId)
                );
              } else {
                navigate(PATHS.UNAUTHORIZED);
              }
            }}
          >
            {dataItem?.hasMultipleItems && <span>+ </span>}
            {dataItem?.orderId}
          </button>
        ),
        email: <span className="font-[500]">{dataItem?.email}</span>,
        date: formatDate(dataItem.date),
        totalPrice:
          Number(dataItem?.totalPrintingCost) +
          Number(dataItem?.totalShippingCost),
        status: (
          <span>
            {statusLabels[dataItem?.status] || "Unknown"}
            {/* Handle unknown statuses */}
          </span>
        ),
      })),
    [orders, navigate]
  );

  return (
    <div className="">
      {tableHeader && (
        <div className="flex items-center justify-between px-[2.1em] py-[1.7em]  ">
          <div className="text-[#1A1E1A] text-[1.8em] font-[500]">
            {tableHeaderName}
          </div>
        </div>
      )}
      <div>
        <CustomTable
          headers={headers}
          data={tableFormattedData}
          footer={true}
          totalPages={maxPages}
          totalEntries={totalEntries}
          rowsPerPage={pageSize}
          setRowsPerPage={setPageSize}
          currentPage={+page + 1}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default ManageOrderComponent;
