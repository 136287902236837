import { useState } from "react";
import {
  BASE_URL,
  ENDPOINT_UPDATE_INVOICE_STATUS,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useUpdateInvoiceStatus = () => {
  const [error, setError] = useState(null);
  const { makeApiCall, loading } = useApi();

  const updateInvoiceStatus = async (invoiceId, newInvoiceStatus) => {
    try {
      setError(null);

      // Send the API call to update the invoice status
      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_UPDATE_INVOICE_STATUS}`,
        method: "PUT", // Assuming it's a PUT request
        data: {
          invoiceId, // Ensure this matches the API's expected format
          newInvoiceStatus, // Ensure this matches the API's expected format
        },
      });

      // Check for a successful response
      if (response.data.success) {
        return true;
      } else {
        throw new Error(
          response.data.message || "Failed to update invoice status"
        );
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
      console.error("Error updating invoice status:", error);
      return false;
    }
  };

  return { updateInvoiceStatus, loading, error };
};

export default useUpdateInvoiceStatus;
