import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import MainLayout from "./components/MainLayout";
import SimpleLayout from "./components/SimpleLayout";
import { PATHS } from "./constants/RouteConstants";
import actions from "./mock/actions.json";
import { useRole } from "./context/RoleContext";

// Import your page components
import Dashboard from "./pages/Dashboard";
import ManageOrders from "./pages/ManageOrders";
import Templates from "./pages/Templates";
// import Users from "./pages/Users";
// import Blogs from "./pages/Blogs";
// import Promos from "./pages/Promos";
// import SubscriptionPacks from "./pages/SubscriptionPacks";
// import ManageFinance from "./pages/ManageFinance";
// import Orders from "./pages/Orders";
// import Printing from "./pages/Printing";
// import Courier from "./pages/Courier";
// import AnalyticsReports from "./pages/AnalyticsReports";
// import PrintingService from "./pages/PrintingService";
// import CourierService from "./pages/CourierService";
import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";
// import CapitalPrintingService from "./pages/CapitalPrintingService";
// import CapitalCourierService from "./pages/CapitalCourierService";
import DedicatedOrder from "./pages/DedicatedOrder";
import Categories from "./pages/Categories";
import { useApp } from "./context/AppState";
import DedicatedOrderPrinter from "./pages/printer/DedicatedOrderPrinter";
import Invoices from "./pages/Invoices";
import DedicatedInvoice from "./pages/DedicatedInvoice";

function App() {
  const { isActionAllowed } = useRole();
  const { isLoggedIn, setIsLoggedIn } = useApp();
  const location = useLocation();
  const navigate = useNavigate();

  const AllRoutes = [
    {
      path: PATHS.BASE,
      mainElement: <MainLayout />,
      isProtected: true,
      isAvailable: true,
      childRoutes: [
        {
          path: PATHS.BASE,
          mainElement: <Dashboard />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ViewDashboard),
        },
        {
          path: PATHS.DASHBOARD,
          mainElement: <Dashboard />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ViewDashboard),
        },
        {
          path: PATHS.MANAGE_ORDERS,
          mainElement: <ManageOrders />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ManageOrders),
        },
        {
          path: PATHS.DEDICATED_ORDER,
          mainElement: <DedicatedOrder />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.DedicatedOrder),
        },
        {
          path: PATHS.DEDICATED_ORDER_PRINTER,
          mainElement: <DedicatedOrderPrinter />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.DedicatedOrderPrinter),
        },
        {
          path: PATHS.TEMPLATES,
          mainElement: <Templates />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ManageTemplates),
        },
        {
          path: PATHS.CATEGORIES,
          mainElement: <Categories />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ManageCategories),
        },
        {
          path: PATHS.INVOICES,
          mainElement: <Invoices />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.ViewInvoice),
        },
        {
          path: PATHS.DEDICATED_INVOICE,
          mainElement: <DedicatedInvoice />,
          isProtected: true,
          isAvailable: isActionAllowed(actions.EditInvoiceStatus),
        },
        // {
        //   path: PATHS.USER_MANAGEMENT,
        //   mainElement: <Users />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.UserManagement),
        // },
        // {
        //   path: PATHS.BLOGS,
        //   mainElement: <Blogs />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ViewBlogs),
        // },
        // {
        //   path: PATHS.PROMOS,
        //   mainElement: <Promos />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ViewPromos),
        // },
        // {
        //   path: PATHS.SUBSCRIPTION_PACKS,
        //   mainElement: <SubscriptionPacks />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ViewPromos),
        // },

        // {
        //   path: PATHS.MANAGE_FINANCE,
        //   mainElement: <ManageFinance />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ManageFinance),
        //   childRoutes: [
        //     {
        //       path: PATHS.ORDERS,
        //       mainElement: <Orders />,
        //       isProtected: true,
        //       isAvailable: isActionAllowed(actions.ViewOrders),
        //     },
        //     {
        //       path: PATHS.PRINTING,
        //       mainElement: <Printing />,
        //       isProtected: true,
        //       isAvailable: isActionAllowed(actions.ViewPrinting),
        //     },
        //     {
        //       path: PATHS.COURIER,
        //       mainElement: <Courier />,
        //       isProtected: true,
        //       isAvailable: isActionAllowed(actions.ViewCourier),
        //     },
        //   ],
        // },
        // {
        //   path: PATHS.ANALYTICS_REPORTS,
        //   mainElement: <AnalyticsReports />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ViewAnalyticsReports),
        // },
        // {
        //   path: PATHS.PRINTING_SERVICE,
        //   mainElement: <PrintingService />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ManagePrintingService),
        // },
        // {
        //   path: PATHS.DEDICATED_PRINTING_SERVICE,
        //   mainElement: <CapitalPrintingService />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ManageDedicatedPrintingService),
        // },

        // {
        //   path: PATHS.COURIER_SERVICE,
        //   mainElement: <CourierService />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ManageCourierService),
        // },
        // {
        //   path: PATHS.DEDICATED_COURIER_SERVICE,
        //   mainElement: <CapitalCourierService />,
        //   isProtected: true,
        //   isAvailable: isActionAllowed(actions.ManageDedicatedCourierService),
        // },

        {
          path: PATHS.UNAUTHORIZED,
          mainElement: <Unauthorized />,
          isProtected: true,
          isAvailable: true,
        },
        {
          path: PATHS.NO_MATCH,
          mainElement: <Navigate to={PATHS.DASHBOARD} replace />,
          isProtected: true,
          isAvailable: true,
        },
      ],
    },
    {
      path: PATHS.BASE,
      mainElement: <SimpleLayout />,
      isProtected: false,
      isAvailable: true,
      childRoutes: [
        {
          path: PATHS.LOGIN,
          mainElement: <Login handleLogin={(val) => setIsLoggedIn(val)} />,
          isProtected: false,
          isAvailable: true,
        },
      ],
    },
  ];

  const allProtectedRoutes = useMemo(
    () => AllRoutes.filter((route) => route.isProtected),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const isRouteProtected = !!allProtectedRoutes.find((route) =>
      location.pathname.startsWith(route)
    );
    if (
      (isRouteProtected || location.pathname === PATHS.UNAUTHORIZED) &&
      !isLoggedIn
    ) {
      navigate(PATHS.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isLoggedIn]);

  const mapRoutes = (routes) => {
    const mapSingleRoute = (route, index) => {
      return route.isProtected && !isLoggedIn ? (
        <Route
          key={index}
          path={route.path}
          element={<Navigate to={PATHS.LOGIN} replace />}
        />
      ) : !route.isAvailable ? (
        <Route
          key={index}
          path={route.path}
          element={<Navigate to={PATHS.UNAUTHORIZED} replace />}
        />
      ) : route.childRoutes && Array.isArray(route.childRoutes) ? (
        <Route key={index} path={route.path} element={route.mainElement}>
          {mapRoutes(route.childRoutes)}
        </Route>
      ) : (
        <Route key={index} path={route.path} element={route.mainElement} />
      );
    };

    if (routes && Array.isArray(routes)) {
      return routes.map((route, index) => mapSingleRoute(route, index));
    }
    return mapSingleRoute(routes);
  };

  return (
    <Routes>
      {mapRoutes(AllRoutes)}
      <Route
        path={PATHS.NO_MATCH}
        element={<Navigate to={PATHS.UNAUTHORIZED} replace />}
      />
    </Routes>
  );
}

export default App;
