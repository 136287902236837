import { useState } from "react";
import { BASE_URL, ENDPOINT_UPDATE_STATUS } from "../../constants/APIConstants";
import useApi from "../useApi";

const useUpdateTemplateStatus = () => {
  const { makeApiCall, loading } = useApi();
  const [error, setError] = useState(null);

  const updateTemplateStatus = async (id, activate) => {
    try {
      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_UPDATE_STATUS}`,
        method: "PUT",
        data: {
          id: id,
          activate: activate,
        },
      });
      if (response.data.success) {
        return true;
      } else {
        throw new Error("Failed to update template status");
      }
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return { updateTemplateStatus, loading, error };
};

export default useUpdateTemplateStatus;
