import React from "react";
import Dropdown from "./Dropdown";

const CustomTable = ({
  headers,
  data,
  footer = false,
  borderRadius = "rounded-[12px]",
  rowsPerPage,
  totalEntries,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const options = ["5", "15", "30", "45", "60"];
  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(Number(value));
    setCurrentPage(0); // Reset to first page when rows per page changes
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Calculate the first and last row numbers for the current page
  const firstRow = (currentPage - 1) * rowsPerPage + 1;
  const lastRow = Math.min(
    currentPage * rowsPerPage,
    firstRow - 1 + data?.length
  );

  return (
    <div
      className={`overflow-x-auto border-[#EDEDED] border-[2px] ${borderRadius}`}
    >
      <table className="min-w-full  ">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={`px-[2em] py-[0.8em] text-left text-[1.4em] font-[400] text-[#1A253D80]  ${
                  index < headers.length - 1 ? "border-r-[2px]" : ""
                } border-[#EDEDED] border-solid border-b-[2px]  tracking-wider ${
                  header?.colClass || ""
                }`}
              >
                {header?.headerText}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className=" ">
          {Array.isArray(data) &&
            data.map((row, rowIndex) => {
              // console.log(row);
              return (
                <tr
                  key={rowIndex}
                  className="border-b-[2px] border-[#EDEDED] border-solid  "
                >
                  {headers.map((header, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`px-[2em] py-[1em]  whitespace-nowrap  text-left text-[1.4em] font-[400] text-[#1A253DCC] ${
                        cellIndex < headers.length - 1 ? "border-r-[2px]" : ""
                      } border-[#EDEDED] border-solid ${
                        header?.colClass || ""
                      }`}
                    >
                      {row?.[header?.dataKey]}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>

        {footer && (
          <tfoot className=" ">
            <tr>
              <td colSpan={headers.length} className="px-[2em] py-[1.2em]">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <Dropdown
                      options={options}
                      value={rowsPerPage}
                      defaultOption="Select"
                      labelText={"Show"}
                      labelTextClassName={
                        "!font-[400] text-[#1A253D80] !text-[1.4em]"
                      }
                      onChange={handleRowsPerPageChange}
                    />
                    <p className="font-[400] text-[#1A253D80] text-[1.4em] ml-2">
                      per page
                    </p>
                  </div>

                  <div className="flex items-center">
                    <p className="text-[#1A253D80] text-[1.4em]">
                      {firstRow}-{lastRow} of {totalEntries}
                    </p>
                    <nav className="inline-flex -space-x-px ml-4">
                      <button
                        onClick={() => handlePageChange(currentPage - 2)}
                        className="py-2 px-3 text-[#1A253D80] text-[1.4em]  rounded-l-lg hover:bg-gray-100 hover:text-gray-600"
                      >
                        &lt;
                      </button>
                      {Array.from({ length: totalPages }, (_, i) => {
                        const value = i;
                        return (
                          <button
                            key={i}
                            onClick={() => handlePageChange(value)}
                            className={`py-2 px-3 text-[#1A253D80] text-[1.4em] ${
                              currentPage === i + 1 ? "font-bold" : ""
                            } hover:bg-gray-100 hover:text-gray-600`}
                          >
                            {value + 1}
                          </button>
                        );
                      })}
                      <button
                        onClick={() => handlePageChange(currentPage)}
                        className="py-2 px-3 text-[#1A253D80] text-[1.4em]  rounded-r-lg hover:bg-gray-100 hover:text-gray-600"
                      >
                        &gt;
                      </button>
                    </nav>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default CustomTable;
