import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import loginLogo from "../assets/svgs/loginLogo.svg";
import useLogin from "../hooks/login/useLogin";
import { useApp } from "../context/AppState";
import { PATHS } from "../constants/RouteConstants";

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState("admin@pb.com");
  const [password, setPassword] = useState("admin");
  const navigate = useNavigate();

  const { isLoggedIn } = useApp();
  const { login, loading, error, shake } = useLogin();

  const handleLogin1 = (e) => {
    e.preventDefault();

    login(username, password, () => {
      handleLogin(true);
      navigate("/dashboard");
    });
  };

  if (isLoggedIn) {
    <Navigate to={PATHS.BASE} replace />;
  }

  return (
    <div className="h-screen w-full flex items-center justify-between px-[18em] gap-[15em] bg-login-gradient">
      {/* Left Section */}
      <div className="flex flex-col items-start justify-center w-[50%] text-white">
        <div className="mb-4">
          <img src={loginLogo} alt="Logo" />
          <div className="text-[8.6em] font-[300] leading-tight mt-2">
            Lorem Ipsum
          </div>
          <div className="text-[8.6em] font-[300]">Dolor Sit</div>
          <p className="text-[2.1em] font-[300] text-[#FFFFFF99] w-[80%]">
            Risus in hendrerit gravida rutrum quisque non tellus orci. Commodo
            odio aenean sed adipiscing diam donec adipiscing tristique risus.
          </p>
        </div>
      </div>

      {/* Right Section - Login Form */}
      <div
        className={`bg-white rounded-2xl shadow-md px-[9em] py-[6em] ${
          shake ? "animate-shake" : ""
        }`}
      >
        <h2 className="text-center text-[3.6em] font-[600] text-[#4A1992]">
          Welcome To PhotoFox
        </h2>
        <p className="text-center text-[#1A253DCC] font-[300] text-[1.6em] mb-[1.5em]">
          Please enter your details to sign in.
        </p>
        {error && (
          <p className={`text-red-500 text-lg italic text-center py-7`}>
            {error}
          </p>
        )}
        <form onSubmit={handleLogin1}>
          <div className="mb-[1.6em]">
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="appearance-none border rounded-[6px] w-full py-4 px-7 text-[#1A253D80] text-[1.4em] font-[300] leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Email Address/Username"
              disabled={loading}
            />
          </div>
          <div className="mb-6">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="appearance-none border rounded-[6px] w-full py-4 px-7 text-[#1A253D80] text-[1.4em] font-[300] leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Password"
              disabled={loading}
            />
          </div>
          <div>
            <button
              style={{
                boxShadow: "0px 0px 19px 0px rgba(49, 224, 247, 0.34)",
              }}
              type="submit"
              className={`mt-10 w-full text-[1.8em] font-light text-white bg-[#49389D] py-[0.6em] rounded-[10px] focus:outline-none focus:shadow-outline transform transition-transform duration-300 ease-in-out hover:scale-105 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
