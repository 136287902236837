import React, { useContext, useState } from "react";
import { popupContext as PopUpContext } from "./context";

export const PopUpState = (props) => {
  // POPUP <<-- START -->>
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupChildren, setPopupChildren] = useState(<></>);
  const showPopup = (popupChildren) => {
    if (popupChildren) {
      setPopupChildren(popupChildren);
      setIsPopupOpen(true);
    } else {
      alert("No Pop Up Component Found");
    }
  };
  const closePopup = () => {
    setPopupChildren(<></>);
    setIsPopupOpen(false);
  };
  // POPUP <<-- END -->>
  return (
    <PopUpContext.Provider value={{ showPopup, closePopup }}>
      {isPopupOpen ? (
        <section className="fixed z-[999999] top-0 left-0 h-svh w-screen bg-[#141B2DCC] grid place-items-center">
          {popupChildren}
        </section>
      ) : (
        <></>
      )}
      {props.children}
    </PopUpContext.Provider>
  );
};

const usePopUp = () => useContext(PopUpContext);
export default usePopUp;
