import React, { useMemo } from "react";
import CustomTable from "../../reusable/CustomTable";
import ToggleSwitch from "../../reusable/ToggleSwitch";

// const highlightText = (text, searchTerm) => {
//   if (!searchTerm) return text;
//   const regex = new RegExp(`(${searchTerm})`, "gi");
//   const parts = text.split(regex);
//   return parts.map((part, index) =>
//     part.toLowerCase() === searchTerm.toLowerCase() ? (
//       <span key={index} className="bg-yellow-200 font-bold">
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };

const TemplatesComponent = ({
  templates,
  page,
  setPage,
  pageSize,
  setPageSize,
  totalEntries,
  tableHeader,
  tableHeaderName,
}) => {
  // Define the headers for the table
  const headers = [
    { headerText: "Name", dataKey: "name" },
    { headerText: "Card ID", dataKey: "cardId" },
    { headerText: "Type", dataKey: "type" },
    { headerText: "Price", dataKey: "price" },
    { headerText: "Categories", dataKey: "categories" },
    { headerText: "Active", dataKey: "active", colClass: "text-center" },
  ];

  // Format table data

  const tableFormattedData = useMemo(
    () =>
      templates.map((dataItem) => ({
        ...dataItem,
        name: (
          <div className="flex items-center gap-2">
            <div>
              <img
                src={dataItem.imgUrl}
                alt="Template"
                className="w-[50px] h-[40px] object-cover rounded-[10px]"
              />
            </div>
            <div className="">{dataItem.name}</div>
            {/* {highlightText(dataItem.name, dataItem.name)} */}
          </div>
        ),
        cardId: (
          <a href={`/card/${dataItem.cardId}`} className="text-blue-500">
            {`#${dataItem.cardId}`}
            {/* {highlightText(dataItem.cardId, dataItem.cardId)} */}
          </a>
        ),
        active: (
          <div className="text-center">
            <ToggleSwitch
              checked={dataItem.active}
              onChange={dataItem?.toggleActive}
            />
          </div>
        ),
      })),
    // eslint-disable-next-line
    [templates]
  );

  return (
    <div className="mt-6">
      {tableHeader && (
        <div className="flex items-center justify-between px-[2.1em] py-[1.7em]  ">
          <div className="text-[#1A1E1A] text-[1.8em] font-[500]">
            {tableHeaderName}
          </div>
        </div>
      )}
      <CustomTable
        tableHeader={tableHeader}
        tableHeaderName={tableHeaderName}
        headers={headers}
        data={tableFormattedData}
        currentPage={page + 1}
        rowsPerPage={pageSize}
        setCurrentPage={setPage}
        setRowsPerPage={setPageSize}
        totalEntries={totalEntries}
        totalPages={Math.ceil(totalEntries / pageSize)}
        footer={true}
      />
    </div>
  );
};

export default TemplatesComponent;
