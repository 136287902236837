import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../reusable/PageHeader";
import Dropdown from "../reusable/Dropdown";
import ConfirmPopup from "../reusable/ConfirmPopup";
import useUpdateInvoiceStatus from "../hooks/manage_invoice/useUpdateInvoiceStatus"; // Import your custom hook
import usePopUp from "../context/PopUpState";
import { PATHS } from "../constants/RouteConstants";

const statusLabels = {
  "-1": "All",
  0: "Pending",
  1: "Paid",
  2: "Cancelled",
};

const DedicatedInvoice = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const { showPopup } = usePopUp();
  const {
    updateInvoiceStatus,
    loading: updateLoading,
    error: updateError,
  } = useUpdateInvoiceStatus();
  const navigate = useNavigate();
  const [invoiceStatus, setInvoiceStatus] = useState("");

  // Debugging Logs
  console.log("Invoice ID from useParams:", id);
  console.log("Fetched state:");
  console.table(state);
  const invoice = { ...state };

  const availableOptions =
    invoice.status === 0
      ? ["Paid", "Cancelled"]
      : invoice.status === 1
      ? ["Cancelled"]
      : [];

  const handleUpdateStatus = async () => {
    const statusCode = Object.keys(statusLabels).find(
      (key) => statusLabels[key] === invoiceStatus
    );
    const success = await updateInvoiceStatus(id, parseInt(statusCode));

    if (success) {
      alert("Invoice status updated successfully");
      navigate(PATHS.INVOICES);
    } else {
      alert("Failed to update invoice status");
    }
  };

  const triggerConfirmPopup = () => {
    showPopup(
      <ConfirmPopup
        title="Confirm Status Update"
        description="Are you sure you want to update the status of this invoice?"
        onConfirm={handleUpdateStatus}
        onCancel={() => console.log("Update canceled")}
      />
    );
  };

  return (
    <div>
      <PageHeader
        path={`Invoices / Invoice #${id}`}
        headerText={`Invoice #${id}`}
        actionComponent={
          <div className="bg-blue-100 text-[#3666eb] text-[1.4em] px-4 py-2 rounded-md">
            {statusLabels[invoice.status]}
          </div>
        }
      />
      <div className="card-item px-6 pb-14 pt-8 bg-white rounded-lg shadow mb-12 mt-12">
        <p className="font-[600] text-[#1A253DE6] text-[2.4em] mb-4">
          Change Status
        </p>
        <Dropdown
          options={availableOptions}
          defaultOption="Change Status"
          height="h-[2.6em]"
          width="w-[10em]"
          onChange={(value) => setInvoiceStatus(value)}
          value={invoiceStatus}
        />
        <button
          onClick={triggerConfirmPopup}
          className={`mt-6 text-white text-[1.2em] px-6 py-2 rounded-md ${
            invoiceStatus === "" || updateLoading
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#49389D] hover:bg-[#372A7D]"
          }`}
          disabled={invoiceStatus === "" || updateLoading}
        >
          {updateLoading ? "Updating..." : "Update Status"}
        </button>
        {updateError && <p className="text-red-500 mt-2">{updateError}</p>}
      </div>
    </div>
  );
};

export default DedicatedInvoice;
