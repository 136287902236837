export const BASE_URL = process.env.REACT_APP_API_URL;

// LOGIN ENDPOINTS
export const ENDPOINT_LOGIN = process.env.REACT_APP_ENDPOINT_LOGIN;

// ORDERS ENDPOINTS
export const ENDPOINT_GET_ORDERS_BY_STATUS =
  process.env.REACT_APP_ENDPOINT_GET_ORDERS_BY_STATUS;

export const ENDPOINT_GET_ORDER_DETAIL =
  process.env.REACT_APP_ENDPOINT_GET_ORDER_DETAIL;

// INVOICE ENDPOINTS
export const ENDPOINT_GET_INVOICES_BY_STATUS =
  process.env.REACT_APP_ENDPOINT_GET_INVOICES_BY_STATUS;

export const ENDPOINT_UPDATE_INVOICE_STATUS =
  process.env.REACT_APP_ENDPOINT_UPDATE_INVOICE_STATUS;

// ADD CONSIGNMENT TO ORDER ITEMS ENDPOINTS
export const ENDPOINT_ADD_CONSIGNMENT =
  process.env.REACT_APP_ENDPOINT_ADD_CONSIGNMENT;

//CATEGORIES ENDPOINTS
export const ENDPOINT_GET_CATEGORIES =
  process.env.REACT_APP_ENDPOINT_GET_CATEGORIES;

export const ENDPOINT_ADD_CATEGORIES =
  process.env.REACT_APP_ENDPOINT_ADD_CATEGORIES;

//TEMPLATE ENDPOINTS
export const ENDPOINT_GET_TEMPLATES =
  process.env.REACT_APP_ENDPOINT_GET_TEMPLATES;

export const ENDPOINT_ADD_TEMPLATE =
  process.env.REACT_APP_ENDPOINT_ADD_TEMPLATE;

export const ENDPOINT_UPLOAD_IMAGE =
  process.env.REACT_APP_ENDPOINT_UPLOAD_IMAGE;

export const ENDPOINT_UPDATE_STATUS =
  process.env.REACT_APP_ENDPOINT_UPDATE_STATUS;

// PRINTER ENDPOINTS
export const ENDPOINT_UPDATE_PRINTER_ORDER_STATUS =
  process.env.REACT_APP_ENDPOINT_UPDATE_PRINTER_ORDER_STATUS;
