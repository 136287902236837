import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/svgs/logo.svg";
import dashboardIcon from "../../assets/svgs/sidebar/dashboard-inactive.svg";
import dashboardIconActive from "../../assets/svgs/sidebar/dashboard.svg";
import manageOrdersIcon from "../../assets/svgs/sidebar/manage-order-inactive.svg";
import manageOrdersIconActive from "../../assets/svgs/sidebar/manage-order.svg";
import templatesIcon from "../../assets/svgs/sidebar/templates-inactive.svg";
import templatesIconActive from "../../assets/svgs/sidebar/templates.svg";
// import userManagementIcon from "../../assets/svgs/sidebar/user-icon-inactive.svg";
// import userManagementIconActive from "../../assets/svgs/sidebar/user-icon.svg";
// import manageContentIcon from "../../assets/svgs/sidebar/manage-content-inactive.svg";
// import manageContentIconActive from "../../assets/svgs/sidebar/manage-content.svg";
// import manageFinanceIcon from "../../assets/svgs/sidebar/manage-finance-inactive.svg";
// import manageFinanceIconActive from "../../assets/svgs/sidebar/manage-finance.svg";
// import analyticsReportsIcon from "../../assets/svgs/sidebar/analytics-reports-inactive.svg";
// import analyticsReportsIconActive from "../../assets/svgs/sidebar/analytics-reports.svg";
// import printingServiceIcon from "../../assets/svgs/sidebar/printing-service-inactive.svg";
// import printingServiceIconActive from "../../assets/svgs/sidebar/printing-service.svg";
// import courierServiceIcon from "../../assets/svgs/sidebar/courier-services-inactive.svg";
// import courierServiceIconActive from "../../assets/svgs/sidebar/courier-services.svg";
import { PATHS } from "../../constants/RouteConstants";
import { logoutUser } from "../../functions/tokenFunctions";
import { useRole } from "../../context/RoleContext";
import actions from "../../mock/actions.json";

function Sidebar() {
  const { isActionAllowed } = useRole();
  const location = useLocation();
  // eslint-disable-next-line
  const [openSection, setOpenSection] = useState(""); // Initially Manage Content is open

  const isActive = (path) => location.pathname === path;

  // Function to toggle sections while ensuring only one is open at a time
  // eslint-disable-next-line
  const handleToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  // Function to handle child click and automatically open the parent section
  const handleLinkClick = (path, section) => {
    if (section) {
      setOpenSection(section); // Open the respective section
    } else {
      // If no section provided, collapse Manage Content and Manage Finance
      setOpenSection(null);
    }
  };

  return (
    <div className=" min-w-[15vw] text-[#FAFAFFB3] p-8 text-[1.4em] bg-sidebar-gradient">
      <div className="flex items-center mb-8">
        <img src={logo} alt="Logo" className="h-10 w-10" />
      </div>
      <nav className="mt-4">
        <ul className="flex flex-col gap-4">
          {/* Dashboard */}
          {isActionAllowed(actions.ViewDashboard) && (
            <li
              className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
                isActive(PATHS.DASHBOARD) &&
                " bg-[#FFFFFF0A] font-medium text-white"
              }`}
              onClick={() => handleLinkClick(PATHS.DASHBOARD)}
            >
              <Link to={PATHS.DASHBOARD} className="flex items-center">
                <img
                  src={
                    isActive(PATHS.DASHBOARD)
                      ? dashboardIconActive
                      : dashboardIcon
                  }
                  alt="Dashboard"
                />
                <span className="ml-4">Dashboard</span>
              </Link>
            </li>
          )}

          {/* Manage Orders */}
          {isActionAllowed(actions.ManageOrders) && (
            <li
              className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
                isActive(PATHS.MANAGE_ORDERS) &&
                " bg-[#FFFFFF0A] font-medium text-white"
              }`}
              onClick={() => handleLinkClick(PATHS.MANAGE_ORDERS)}
            >
              <Link to={PATHS.MANAGE_ORDERS} className="flex items-center">
                <img
                  src={
                    isActive(PATHS.MANAGE_ORDERS)
                      ? manageOrdersIconActive
                      : manageOrdersIcon
                  }
                  alt="Manage Orders"
                />
                <span className="ml-4">Manage Orders</span>
              </Link>
            </li>
          )}

          {/* Categories */}
          {isActionAllowed(actions.ManageCategories) && (
            <li
              className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
                isActive(PATHS.CATEGORIES) &&
                " bg-[#FFFFFF0A] font-medium text-white"
              }`}
              onClick={() => handleLinkClick(PATHS.CATEGORIES)}
            >
              <Link to={PATHS.CATEGORIES} className="flex items-center">
                <img
                  src={
                    isActive(PATHS.CATEGORIES)
                      ? templatesIconActive
                      : templatesIcon
                  }
                  alt="Categories"
                />
                <span className="ml-4">Categories</span>
              </Link>
            </li>
          )}
          {/* Templates */}
          {isActionAllowed(actions.ManageTemplates) && (
            <li
              className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
                isActive(PATHS.TEMPLATES) &&
                " bg-[#FFFFFF0A] font-medium text-white"
              }`}
              onClick={() => handleLinkClick(PATHS.TEMPLATES)}
            >
              <Link to={PATHS.TEMPLATES} className="flex items-center">
                <img
                  src={
                    isActive(PATHS.TEMPLATES)
                      ? templatesIconActive
                      : templatesIcon
                  }
                  alt="Templates"
                />
                <span className="ml-4">Templates</span>
              </Link>
            </li>
          )}

          {/* Invoices */}
          {isActionAllowed(actions.ViewInvoice) && (
            <li
              className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
                isActive(PATHS.INVOICES) &&
                " bg-[#FFFFFF0A] font-medium text-white"
              }`}
              onClick={() => handleLinkClick(PATHS.INVOICES)}
            >
              <Link to={PATHS.INVOICES} className="flex items-center">
                <img
                  src={
                    isActive(PATHS.INVOICES)
                      ? templatesIconActive
                      : templatesIcon
                  }
                  alt="Invoices"
                />
                <span className="ml-4">Invoices</span>
              </Link>
            </li>
          )}

          {/* User Management */}
          {/* <li
            className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
              isActive(PATHS.USER_MANAGEMENT) &&
              " bg-[#FFFFFF0A] font-medium text-white"
            }`}
            onClick={() => handleLinkClick(PATHS.USER_MANAGEMENT)}
          >
            <Link to={PATHS.USER_MANAGEMENT} className="flex items-center">
              <img
                src={
                  isActive(PATHS.USER_MANAGEMENT)
                    ? userManagementIconActive
                    : userManagementIcon
                }
                alt="User Management"
              />
              <span className="ml-4">User Management</span>
            </Link>
          </li> */}

          {/* Manage Content Section */}
          {/* <li
            className={`px-7  py-4 hover:bg-[#FFFFFF0A] rounded-lg  ${
              openSection === "ManageContent" ? "bg-[#FFFFFF0A]" : ""
            }`}
          >
            <button
              onClick={() => handleToggle("ManageContent")}
              className="flex items-center w-full text-left focus:outline-none"
            >
              <img
                src={
                  openSection === "ManageContent"
                    ? manageContentIconActive
                    : manageContentIcon
                }
                alt="Manage Content"
              />
              <span className="ml-4 font-medium">Manage Content</span>
              <span className="ml-auto">
                {openSection === "ManageContent" ? "-" : "+"}
              </span>
            </button>
            {openSection === "ManageContent" && (
              <ul className="mt-2 ml-[2.8em] relative"> */}
          {/* Blogs child item with dot */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.BLOGS) ? " font-medium text-white" : ""
                  }`}
                  onClick={() => handleLinkClick(PATHS.BLOGS, "ManageContent")}
                >
                  {isActive(PATHS.BLOGS) && (
                    <span className="absolute left-[-0.2rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.BLOGS}>Blogs</Link>
                </li> */}

          {/* Promos child item */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.PROMOS) ? " font-medium  text-white" : ""
                  }`}
                  onClick={() => handleLinkClick(PATHS.PROMOS, "ManageContent")}
                >
                  {isActive(PATHS.PROMOS) && (
                    <span className="absolute left-[-0.2rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.PROMOS}>Promos</Link>
                </li> */}

          {/* Subscription Packs child item */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.SUBSCRIPTION_PACKS)
                      ? " font-medium text-white"
                      : ""
                  }`}
                  onClick={() =>
                    handleLinkClick(PATHS.SUBSCRIPTION_PACKS, "ManageContent")
                  }
                >
                  {isActive(PATHS.SUBSCRIPTION_PACKS) && (
                    <span className="absolute left-[-0.2rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.SUBSCRIPTION_PACKS}>Subscription Packs</Link>
                </li>
              </ul>
            )}
          </li> */}

          {/* Manage Finance Section */}
          {/* <li
            className={`px-7  py-4 mt-4 hover:bg-[#FFFFFF0A] rounded-lg  ${
              openSection === "ManageFinance" ? "bg-[#FFFFFF0A]" : ""
            }`}
          >
            <button
              onClick={() => handleToggle("ManageFinance")}
              className="flex items-center w-full text-left focus:outline-none"
            >
              <img
                src={
                  openSection === "ManageFinance"
                    ? manageFinanceIconActive
                    : manageFinanceIcon
                }
                alt="Manage Finance"
              />
              <span className="ml-4 font-medium">Manage Finance</span>
              <span className="ml-auto">
                {openSection === "ManageFinance" ? "-" : "+"}
              </span>
            </button>
            {openSection === "ManageFinance" && (
              <ul className="mt-2 ml-[2.8em] relative"> */}
          {/* Orders child item */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.ORDERS) ? " font-medium text-white" : ""
                  }`}
                  onClick={() => handleLinkClick(PATHS.ORDERS, "ManageFinance")}
                >
                  {isActive(PATHS.ORDERS) && (
                    <span className="absolute left-[-0.1rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.ORDERS}>Orders</Link>
                </li> */}

          {/* Printing child item */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.PRINTING) ? " font-medium text-white" : ""
                  }`}
                  onClick={() =>
                    handleLinkClick(PATHS.PRINTING, "ManageFinance")
                  }
                >
                  {isActive(PATHS.PRINTING) && (
                    <span className="absolute left-[-0.1rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.PRINTING}>Printing</Link>
                </li> */}

          {/* Courier child item */}
          {/* <li
                  className={`relative pl-6 py-2  rounded-lg ${
                    isActive(PATHS.COURIER) ? " font-medium text-white" : ""
                  }`}
                  onClick={() =>
                    handleLinkClick(PATHS.COURIER, "ManageFinance")
                  }
                >
                  {isActive(PATHS.COURIER) && (
                    <span className="absolute left-[-0.1rem] top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-white"></span>
                  )}
                  <Link to={PATHS.COURIER}>Courier</Link>
                </li>
              </ul>
            )}
          </li> */}

          {/* Analytics & Reports */}
          {/* <li
            className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
              isActive(PATHS.ANALYTICS_REPORTS) &&
              " bg-[#FFFFFF0A] font-medium text-white"
            }`}
            onClick={() => handleLinkClick(PATHS.ANALYTICS_REPORTS)}
          >
            <Link to={PATHS.ANALYTICS_REPORTS} className="flex items-center">
              <img
                src={
                  isActive(PATHS.ANALYTICS_REPORTS)
                    ? analyticsReportsIconActive
                    : analyticsReportsIcon
                }
                alt="Analytics & Reports"
              />
              <span className="ml-4">Analytics & Reports</span>
            </Link>
          </li> */}

          {/* Printing Service */}
          {/* <li
            className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
              isActive(PATHS.PRINTING_SERVICE) &&
              " bg-[#FFFFFF0A] font-medium text-white"
            }`}
            onClick={() => handleLinkClick(PATHS.PRINTING_SERVICE)}
          >
            <Link to={PATHS.PRINTING_SERVICE} className="flex items-center">
              <img
                src={
                  isActive(PATHS.PRINTING_SERVICE)
                    ? printingServiceIconActive
                    : printingServiceIcon
                }
                alt="Printing Service"
              />
              <span className="ml-4">Printing Service</span>
            </Link>
          </li> */}

          {/* Courier Service */}
          {/* <li
            className={`px-7 py-4 hover:bg-[#FFFFFF0A] rounded-lg ${
              isActive(PATHS.COURIER_SERVICE) &&
              " bg-[#FFFFFF0A] font-medium text-white"
            }`}
            onClick={() => handleLinkClick(PATHS.COURIER_SERVICE)}
          >
            <Link to={PATHS.COURIER_SERVICE} className="flex items-center">
              <img
                src={
                  isActive(PATHS.COURIER_SERVICE)
                    ? courierServiceIconActive
                    : courierServiceIcon
                }
                alt="Courier Service"
              />
              <span className="ml-4">Courier Service</span>
            </Link>
          </li> */}
          {/* LOGOUT */}
          <li
            className={`px-7 py-4 cursor-pointer hover:bg-[#FFFFFF0A] rounded-lg`}
            onClick={logoutUser}
          >
            <section className="flex items-center">
              <span className="ml-4">Logout</span>
            </section>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
