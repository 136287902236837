import React from "react";

const StatusTabs = ({ selectedTab, onSelectTab }) => {
  // Define the statuses array with the new requirements
  const statuses = [
    { name: "All", statusCode: -1, label: "All" },
    { name: "Pending", statusCode: 0, label: "Pending" },
    { name: "Paid", statusCode: 1, label: "Paid" },
    { name: "Cancelled", statusCode: 2, label: "Cancelled" },
  ];

  return (
    <div className="flex gap-4">
      {statuses.map((status) => (
        <div className="flex items-center" key={status.statusCode}>
          <button
            className={`px-2 pb-2 ${
              selectedTab === status.statusCode
                ? "text-[#4A1992] font-[500] text-[1.4em]"
                : "text-[#1A253D80] font-[300] text-[1.4em] hover:text-purple-700"
            }`}
            onClick={() => onSelectTab(status.statusCode)}
          >
            {status.label}
          </button>
        </div>
      ))}
    </div>
  );
};

export default StatusTabs;
