import { useState, useEffect } from "react";
import {
  BASE_URL,
  ENDPOINT_GET_INVOICES_BY_STATUS,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useFetchInvoices = () => {
  const { makeApiCall, loading } = useApi();
  const [invoices, setInvoices] = useState([]);
  const [invoicesInfo, setInvoicesInfo] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(null);

  const updateFilter = (status, page, size) => {
    setFilter({
      status: status,
      page: page,
      pageSize: size,
    });
  };

  useEffect(() => {
    const fetchInvoices = async (status, page, pageSize) => {
      try {
        const response = await makeApiCall({
          url: `${BASE_URL}${ENDPOINT_GET_INVOICES_BY_STATUS}`,
          params: {
            status,
            page,
            pageSize,
          },
        });
        const { data } = response?.data;

        if (response.data.success) {
          setInvoices(data?.items); // Update the invoices state
          setInvoicesInfo({ totalEntries: data?.totalEntries });
        } else {
          throw new Error("Failed to retrieve invoices");
        }
      } catch (error) {
        console.error(
          "Error fetching invoices:",
          error.response || error.message
        );
        setError(error.message);
      }
    };

    if (filter) {
      fetchInvoices(filter.status, filter.page, filter.pageSize); // Call when filter changes
    }
  }, [filter]);

  return { invoices, invoicesInfo, loading, error, filter, updateFilter };
};

export default useFetchInvoices;
