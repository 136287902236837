import { useState } from "react";
import {
  BASE_URL,
  ENDPOINT_UPDATE_PRINTER_ORDER_STATUS,
} from "../../../constants/APIConstants";
import useApi from "../../useApi";

const useUpdateOrderStatus = () => {
  const [error, setError] = useState(null);
  const { makeApiCall, loading } = useApi();

  const updateOrderStatus = async (orderId, newOrderStatus) => {
    try {
      setError(null);

      // console.log("Sending request to update order status:", {
      //   orderId,
      //   newOrderStatus,
      // }); // Log request payload

      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_UPDATE_PRINTER_ORDER_STATUS}`,
        method: "PUT", // Use PUT as per the assumption that it's correct
        data: {
          orderId, // Ensure this matches the API's expected format
          newOrderStatus, // Ensure this matches the API's expected format
        },
      });

      // console.log("Response from update order status API:", response.data); // Log the response for debugging

      if (response.data.success) {
        return true;
      } else {
        throw new Error(
          response.data.message || "Failed to update order status"
        );
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
      console.error("Error updating order status:", error); // Log error for debugging
      return false;
    }
  };

  return { updateOrderStatus, loading, error };
};

export default useUpdateOrderStatus;
