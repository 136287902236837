import { useState } from "react";
import axios from "axios";
import useRefreshToken from "./login/useRefreshToken";

const useApi = () => {
  const token = sessionStorage.getItem("authToken");
  const refreshToken = useRefreshToken();

  // State for loading
  const [loading, setLoading] = useState(false);

  // Main function to make API calls
  const makeApiCall = async ({
    url,
    method = "GET",
    data = {},
    params = {}, // Add params for query parameters
    headers = { "Content-Type": "application/json" },
    retries = 1,
    onSuccess = null, // Optional success callback
    onError = null, // Optional error callback
  }) => {
    setLoading(true); // Set loading state to true before the request

    try {
      // Exit early if no token is found
      if (!token) {
        throw new Error("No auth token found. Please log in again.");
      }
      // Attempt API call with current access token
      const response = await axios({
        url,
        method,
        data,
        params, // Pass params for query parameters
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      });

      // If the request is successful, execute the success callback if provided
      if (onSuccess) onSuccess(response.data);
      return response; // Return API response
    } catch (error) {
      // Handle token expiration (401 Unauthorized)
      if (error.response && error.response.status === 401 && retries > 0) {
        const newAccessToken = await refreshToken();

        // Retry the original request with the new access token
        return await makeApiCall({
          url,
          method,
          data,
          params, // Retain original query params
          headers: { ...headers, Authorization: `Bearer ${newAccessToken}` },
          retries: retries - 1, // Decrement retry count
          onSuccess,
          onError,
        });
      } else {
        // Handle any other errors
        if (onError) onError(error); // Trigger error callback if provided
        throw error; // Forward error for handling in the component
      }
    } finally {
      setLoading(false); // Set loading to false after request completes (success or failure)
    }
  };

  // Return the API call function and the loading state
  return { makeApiCall, loading };
};

export default useApi;
