import { useState } from "react";
import useApi from "../useApi";
import { BASE_URL, ENDPOINT_UPLOAD_IMAGE } from "../../constants/APIConstants";

const useUploadImage = () => {
  const { makeApiCall, loading } = useApi();
  const [error, setError] = useState(null);

  const uploadImage = async (file, imageType) => {
    try {
      setError(null);
      const authToken = sessionStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("No auth token found. Please log in again.");
      }

      const formData = new FormData();
      formData.append("image", file);
      formData.append("imageType", imageType);

      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_UPLOAD_IMAGE}`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        return response.data.data; // return the uploaded image name
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      setError(error.message);
      return null;
    }
  };

  return { uploadImage, loading, error };
};

export default useUploadImage;
