import { useState, useEffect } from "react";
// import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_ORDER_DETAIL,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useFetchOrderDetails = (orderId) => {
  const { makeApiCall, loading } = useApi();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  const fetchOrderDetails = async () => {
    try {
      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_GET_ORDER_DETAIL}${orderId}`,
      });
      // console.log(response);

      if (response.data.success) {
        setOrderDetails(response.data.data); // Update the state with fetched data
      } else {
        throw new Error("Failed to fetch order details");
      }
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    fetchOrderDetails(); // Fetch order details when the hook is invoked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return { fetchOrderDetails, orderDetails, loading, error };
};

export default useFetchOrderDetails;
