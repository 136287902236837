import React, { useEffect, useState } from "react";
import TextInput from "../../reusable/TextInput";
// import Dropdown from "../../reusable/Dropdown";
import RadioButton from "../../reusable/RadioButton";
import UploadImage from "../../reusable/UploadImage";
import useFetchCategories from "../../hooks/categories/useFetchCategories";
import useUploadImage from "../../hooks/templates/useUploadImage";
import Select from "react-select";
import useAddTemplate from "../../hooks/templates/useAddTemplate";

const AddTemplate = ({ onSave }) => {
  const { categories, updateFilter: fetchCategories } = useFetchCategories();
  const { uploadImage, loading } = useUploadImage();
  const addTemplate = useAddTemplate();
  const [templateName, setTemplateName] = useState("");
  const [selectedGreeting, setSelectedGreeting] = useState([]);
  const [price, setPrice] = useState("");
  const [templateType, setTemplateType] = useState("Free");
  const [error, setError] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [placeholders, setPlaceholders] = useState([
    { width: "", height: "", left: "", top: "" },
  ]); // State for placeholders
  const [cardWidth, setCardWidth] = useState(""); // State for card width
  const [cardHeight, setCardHeight] = useState(""); // State for card height

  useEffect(() => {
    fetchCategories(0, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for select changes
  const handleSelectGreeting = (selectedValues) => {
    setSelectedGreeting(selectedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validation
    if (
      !templateName ||
      !selectedGreeting ||
      selectedGreeting.length === 0 ||
      (templateType === "Premium" ? !price : false) ||
      !thumbnailImage ||
      !mainImage
    ) {
      setError("Please fill in all the fields, including the images.");
      return;
    }

    const mainImageName = await uploadImage(mainImage, 1);

    if (!mainImageName) {
      setError("Failed to upload Main Image");
      return;
    }

    const thumbnailImageName = await uploadImage(thumbnailImage, 2);

    if (!thumbnailImageName) {
      setError("Failed to upload Main Image");
      return;
    }

    // Prepare new template data
    const newTemplate = {
      categoryIds: selectedGreeting.map((category) => category.value),
      name: templateName,
      premium: templateType === "Premium",
      cost: Number(price) || 0,
      thumbnailImageName: thumbnailImageName,
      mainImageName: mainImageName,
      mainImageJson: {
        Card: {
          CardWidth: parseFloat(cardWidth), // Ensure numeric type
          CardHeight: parseFloat(cardHeight), // Ensure numeric type
          NoOfPlaceholders: placeholders.length,
          Placeholders: placeholders.map((placeholder, index) => ({
            Order: index + 1,
            Width: placeholder?.width,
            Height: placeholder?.height,
            Left: placeholder?.left,
            Top: placeholder?.top,
          })),
        },
      },
    };

    await addTemplate(newTemplate);

    // Call the onSave function to save the new template
    onSave(newTemplate);
    console.log(newTemplate);
    // Reset the form
    setTemplateName("");
    setSelectedGreeting([]);
    setPrice("");
    setTemplateType("Free");
    setThumbnailImage(null);
    setMainImage(null);
    setPlaceholders([{ width: "", height: "", left: "", top: "" }]); // Reset placeholders with initial empty object
    setCardWidth("");
    setCardHeight("");
  };

  // Handle image uploads
  const handleThumbnailImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setThumbnailImage(file);
    }
  };

  const handleMainImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMainImage(file);
    }
  };

  // Add a new placeholder
  const addPlaceholder = () => {
    setPlaceholders([
      ...placeholders,
      { width: "", height: "", left: "", top: "" },
    ]);
  };

  // Delete a placeholder
  const deletePlaceholder = (index) => {
    const updatedPlaceholders = placeholders.filter((_, i) => i !== index);
    setPlaceholders(updatedPlaceholders);
  };

  // Handle input changes for placeholders (with numeric conversion)
  const handlePlaceholderChange = (index, field, value) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return; // Ignore non-numeric input
    const updatedPlaceholders = [...placeholders];
    updatedPlaceholders[index][field] = parsedValue; // Store as number
    setPlaceholders(updatedPlaceholders);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-[#1A253D] text-[2.2em] font-medium">
        Add New Template
      </div>
      <p className="text-[#1A253DB2] text-[1.4em] mb-[4.3em]">
        Pulvinar hendrerit accumsan placerat dolor, semper auctor
      </p>

      {loading ? (
        <div></div>
      ) : (
        <>
          <div className="flex items-center flex-wrap gap-4">
            <TextInput
              placeholder="Template Name"
              width="w-[23em]"
              height="h-[3em]"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />

            <Select
              className="w-full"
              isMulti
              value={selectedGreeting}
              options={categories.map((cat) => ({
                value: cat.id,
                label: cat.name,
              }))}
              onChange={handleSelectGreeting}
              required
            />
          </div>

          <div className="flex items-center space-x-4 mt-[4.3em] mb-[2em]">
            <p className="text-[1.4em] text-[#1A253DB2]">Campaign Type:</p>
            <RadioButton
              label="Free"
              name="type"
              value="Free"
              checked={templateType === "Free"}
              onChange={() => setTemplateType("Free")}
            />
            <RadioButton
              label="Premium"
              name="type"
              value="Premium"
              checked={templateType === "Premium"}
              onChange={() => setTemplateType("Premium")}
            />
          </div>
          {templateType === "Premium" && (
            <TextInput
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              width="w-[23em]"
              height="h-[3em]"
              className="mt-4"
              required
            />
          )}

          {/* Upload Image Section */}
          <div className="flex gap-4 ">
            <div>
              <UploadImage onImageUpload={handleThumbnailImageUpload} />
              <p className="text-[#1A253D80] text-[1.2em] mb-3">
                Thumbnail Image
              </p>
            </div>
            <div>
              <UploadImage onImageUpload={handleMainImageUpload} />
              <p className="text-[#1A253D80] text-[1.2em] mb-3">Main Image</p>
            </div>
          </div>
          {/* { Card Width and Card Height Inputs } */}
          <div className="flex laptop:flex-row flex-col gap-4 mt-4 mb-9">
            <TextInput
              placeholder="Card Width"
              value={cardWidth}
              onChange={(e) => setCardWidth(e.target.value)}
              width="w-[23em]"
              height="h-[3em]"
              required
            />
            <TextInput
              placeholder="Card Height"
              value={cardHeight}
              onChange={(e) => setCardHeight(e.target.value)}
              width="w-[23em]"
              height="h-[3em]"
              required
            />
          </div>
          <p className="block mb-[1em] text-[1.9em] text-[#1A253DCC] font-medium">
            Placeholders:{" "}
          </p>
          {/* Render the placeholder sections */}
          {placeholders.map((placeholder, index) => (
            <section
              key={index}
              className="mb-4 flex flex-col gap-5 bg-white border-[1.5px] rounded-lg w-max p-4 relative"
            >
              {/* { Cross button to delete placeholder } */}
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => deletePlaceholder(index)}
                  className="absolute top-2 right-2 bg-gray-500 text-white px-2 py-1 rounded-full"
                >
                  ✕
                </button>
              )}

              <p className="text-[#1a253dcb] text-[1.2em] mb-3">
                Group{index + 1}
              </p>
              <TextInput
                type="number"
                placeholder="Width"
                value={placeholder.width}
                onChange={(e) =>
                  handlePlaceholderChange(index, "width", e.target.value)
                }
                width="w-[23em]"
                height="h-[3em]"
                required
              />
              <TextInput
                type="number"
                placeholder="Height"
                value={placeholder.height}
                onChange={(e) =>
                  handlePlaceholderChange(index, "height", e.target.value)
                }
                width="w-[23em]"
                height="h-[3em]"
                required
              />
              <TextInput
                type="number"
                placeholder="Left"
                value={placeholder.left}
                onChange={(e) =>
                  handlePlaceholderChange(index, "left", e.target.value)
                }
                width="w-[23em]"
                height="h-[3em]"
                required
              />
              <TextInput
                type="number"
                placeholder="Top"
                value={placeholder.top}
                onChange={(e) =>
                  handlePlaceholderChange(index, "top", e.target.value)
                }
                width="w-[23em]"
                height="h-[3em]"
                required
              />
            </section>
          ))}
          {/* Add Placeholder Button */}
          <button
            type="button"
            onClick={addPlaceholder}
            className="mt-6 mb-6 bg-slate-400 text-[1.4em] text-white px-4 py-2 rounded flex items-center"
          >
            <span className="mr-2">Add Placeholder</span>
            <span>+</span>
          </button>
        </>
      )}

      {error && <div className="text-red-500 mt-4">{error}</div>}

      <button
        type="submit"
        className="mt-[2em] bg-[#49389D] text-[1.8em] font-light text-white px-7 py-2 rounded-lg"
      >
        {loading ? "Saving..." : "Save"}
      </button>
    </form>
  );
};

export default AddTemplate;
