import React from "react";

const PageHeader = ({ path, headerText, actionComponent }) => {
  return (
    <div className="">
      <div className=" text-[#1A1E1ACC] text-[1.4em]">{path}</div>
      <div className="flex items-center justify-between ">
        <h1 className="text-[2em] font-semibold text-[#1A1E1A]">
          {headerText}
        </h1>
        <div className="">{actionComponent}</div>
      </div>
    </div>
  );
};

export default PageHeader;
