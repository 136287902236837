import React from "react";
const Dropdown = ({
  labelText,
  options,
  defaultOption,
  labelTextClassName,
  value,
  onChange,
  height = "h-12", // Default height is h-12, can be overridden
  width = "w-full",
  required = false, // New required prop
}) => {
  const handleOptionChange = (event) => {
    const value = event.target.value;
    // Call the onChange callback function if it's provided
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="flex items-center">
      {labelText && (
        <label
          className={`block font-[500] text-[1.8em] mr-2 ${labelTextClassName}`}
        >
          {labelText}
        </label>
      )}
      <div
        className={`flex items-center justify-center text-[1.4em] font-[300] text-[#1A253D80]  ${width} ${height} border border-[1A253D4D] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400`}
      >
        <select
          value={value}
          onChange={handleOptionChange}
          className={`block outline-none h-full bg-transparent w-full cursor-pointer px-3`}
          required={required} // Apply required attribute
        >
          <option value="" disabled>
            {defaultOption}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option?.value || option}>
              {option?.name || option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
