import React from "react";
import usePopUp from "../../context/PopUpState";

const AddSuccessPopup = () => {
  const { closePopup } = usePopUp();
  return (
    <div className="py-[5em] w-[30%] flex flex-col justify-center items-center bg-white rounded-[18px] shadow-lg">
      <p className="text-[2.2em] font-medium">Template Added successfully!</p>
      <p className="text-[1.6em] font-light text-[#1A253D99]">
        Tempplate Added successfully!
      </p>

      <button
        onClick={closePopup}
        className="mt-14 text-[#49389D] text-[1.8em] py-2 px-6 rounded-lg border-[#49389D] border-2 hover:bg-[#49389D] hover:text-white transition-colors"
      >
        Close
      </button>
    </div>
  );
};

export default AddSuccessPopup;
