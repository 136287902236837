import React, { useMemo } from "react";
import CustomTable from "../../reusable/CustomTable";

const CategoriesComponent = ({
  categories,
  maxPages,
  totalEntries,
  page,
  setPage,
  setPageSize,
  pageSize,
  tableHeader = true,
  tableHeaderName = "Categories",
}) => {
  // Define headers for the table
  const headers = [
    { headerText: "Category ID", dataKey: "id" },
    { headerText: "Category Name", dataKey: "name" },
  ];

  // Format the data for rendering in the table
  const tableFormattedData = useMemo(
    () =>
      Array.isArray(categories) &&
      categories.map((dataItem) => ({
        ...dataItem,
        id: <span className="font-[500]">{dataItem?.id}</span>,
        name: <span className="font-[500]">{dataItem?.name}</span>,
      })),
    [categories]
  );

  return (
    <div className="">
      {tableHeader && (
        <div className="flex items-center justify-between px-[2.1em] py-[1.7em]  ">
          <div className="text-[#1A1E1A] text-[1.8em] font-[500]">
            {tableHeaderName}
          </div>
        </div>
      )}
      <div>
        <CustomTable
          headers={headers}
          data={tableFormattedData}
          footer={true}
          totalPages={maxPages}
          totalEntries={totalEntries}
          rowsPerPage={pageSize}
          setRowsPerPage={setPageSize}
          currentPage={+page + 1}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default CategoriesComponent;
