import { useState } from "react";
import axios from "axios";
import { BASE_URL, ENDPOINT_LOGIN } from "../../constants/APIConstants";
import { saveToken } from "../../functions/tokenFunctions";
import { useRole } from "../../context/RoleContext";

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [shake, setShake] = useState(false);
  const { saveRole } = useRole();

  const login = async (username, password, onSuccess) => {
    if (!username || !password) {
      setError("Please enter both username and password");
      setShake(true);
      setTimeout(() => setShake(false), 600); // Remove shake class after animation
      return;
    }

    setLoading(true);
    setError("");

    try {
      // const response = await axios.post(`${BASE_URL}${ENDPOINT_LOGIN}`, {
      //   email: username,
      //   password,
      // });

      const response = await axios.request({
        method: "POST",
        url: `${BASE_URL}${ENDPOINT_LOGIN}`,
        data: {
          email: username,
          password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { success, message, data } = response.data;
      if (success) {
        // Handle success - Save token or redirect
        saveToken(data?.tokens);
        saveRole(data?.role);
        onSuccess();
      } else {
        setError(message || "Invalid username or password");
        setShake(true);
        setTimeout(() => setShake(false), 600);
      }
    } catch (error) {
      setError("Login failed. Please try again.");
      setShake(true);
      setTimeout(() => setShake(false), 600);
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error, shake };
};

export default useLogin;
