// App.js
import React from "react";
import StatCard from "../../reusable/StatCard";

const cardData = [
  { title: "Total Orders", value: "22" },
  { title: "Delivered On Time", value: "10/22" },
  { title: "Cancellation Rate", value: "5/22" },
  { title: "Return Rate", value: "10/22" },
  { title: "Pending Orders", value: "10/22" },
];

function StatsCards() {
  return (
    <div className="grid grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-5 gap-[1.3em] mt-14 mb-[5.8em]">
      {cardData.map((card, index) => (
        <StatCard key={index} title={card.title} value={card.value} />
      ))}
    </div>
  );
}

export default StatsCards;
