import React, { useEffect, useState } from "react";
import ManageOrderComponent from "../../components/manage_order/ManageOrderComponent";
import useFetchOrders from "../../hooks/manage_orders/useFetchOrders"; // Import custom hook

function PendingOrders() {
  const { orders, ordersInfo, loading, error, updateFilter } = useFetchOrders();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    updateFilter(0, page, pageSize);
    // eslint-disable-next-line
  }, [page, pageSize]);

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message
  }

  return (
    <ManageOrderComponent
      orders={orders}
      maxPages={Math.ceil(ordersInfo?.totalEntries / pageSize)}
      totalEntries={ordersInfo?.totalEntries}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      tableHeaderName={"Pending Orders"} // Adjust table header name
    />
  );
}

export default PendingOrders;
