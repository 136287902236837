import React from "react";
import { formatDateTime } from "../functions/dateUtils";

// CardItem Component
const CardItem = ({
  card,
  editing,
  consignmentNumber,
  onConsignmentChange,
}) => {
  // Define the full set of tracking statuses and labels
  const trackingStatuses = [
    "Queued for Printing",
    "In Printing",
    "Ready for Pickup",
    "Ready for Shipment",
    "In Transit",
    "Delivered",
  ];

  // Helper function to determine the text color based on the tracking status
  const getStatusColor = (status) => {
    switch (status) {
      case 0: // Queued for Printing
        return "text-yellow-500";
      case 1: // In Printing
        return "text-blue-500";
      case 2: // Ready for Pickup
        return "text-purple-500";
      case 3: // Ready for Shipment
        return "text-orange-500";
      case 4: // In Transit
        return "text-indigo-500";
      case 5: // Delivered
        return "text-green-500";
      default:
        return "text-gray-500"; // Default color for unknown statuses
    }
  };

  // Helper function to map tracking history status to corresponding label
  const getTrackingStatusLabel = (index, history) => {
    const progress = history[index]; // Check if tracking history exists for this index
    return progress
      ? trackingStatuses[progress.trackingStatus]
      : trackingStatuses[index]; // Use default status if missing
  };

  // Helper function to map statusChangedAt value or fallback to "To Be Confirmed"
  const getStatusChangedAt = (index, history) => {
    const progress = history[index];
    return progress && progress.statusChangedAt
      ? formatDateTime(progress.statusChangedAt)
      : "To Be Confirmed";
  };

  return (
    <div className="card-item p-6 bg-white rounded-lg shadow mb-8">
      {/* Card Name and Estimated Delivery */}
      <div className="flex justify-between items-center mb-4">
        <div>
          <h3 className="font-semibold  text-[#1A253D] text-[2.4em]">
            {card.cardName}
          </h3>
          <p className="text-[1.4em] text-gray-500">
            Estimated delivery date:
            <span className="font-semibold">
              {card.estimatedDelivery || "To Be Confirmed"}
            </span>
          </p>
        </div>
        <span className="text-[1.6em] text-blue-500 cursor-pointer">
          Card ID: #{card.id}
        </span>
      </div>

      {/* Current Tracking Status */}
      <p className="text-[1.7em] text-gray-500">
        Current Status:{" "}
        <span
          className={`font-semibold ${getStatusColor(
            card.currentTrackingStatus
          )}`}
        >
          {trackingStatuses[card.currentTrackingStatus]}{" "}
          {/* Current status label */}
        </span>
      </p>

      {/* Consignment Input Field */}
      {editing ? (
        <div className="mt-4">
          <label className="block text-[1.4em] text-gray-700">
            Consignment ID:
          </label>
          <input
            type="text"
            value={consignmentNumber}
            onChange={(e) => onConsignmentChange(e.target.value)}
            className="border rounded-md px-4 py-2 mt-2 w-full text-[1.4em]"
            placeholder="Enter Consignment ID"
          />
        </div>
      ) : (
        <p className="text-[1.4em] mt-4">
          Consignment ID: {card.consignmentNumber || "N/A"}
        </p>
      )}

      <div className="h-[0.1em] bg-[#e5e5e5b9] my-8"></div>

      {/* Shipment Progress */}
      <div className="progress-info">
        <h3 className="font-semibold text-[#1A253D] text-[2em] mb-4">
          Shipment Progress
        </h3>
        {/* Loop through all trackingStatuses, not just card.trackingHistory */}
        {trackingStatuses.map((status, index) => (
          <div
            key={index}
            className={`mb-8 pl-4 border-l-2 ${
              index === card.trackingHistory.length - 1
                ? "border-[#059A3D]" // Green border for the last/current status
                : "border-gray-300" // Gray border for previous statuses
            }`}
          >
            {/* Handle missing statusChangedAt values */}
            <p className="text-[1.6em] font-semibold flex items-center">
              {getStatusChangedAt(index, card.trackingHistory)}{" "}
              {/* Show statusChangedAt */}
              {index === card.trackingHistory.length - 1 && (
                <span className="ml-4 bg-[#059A3D] text-[0.9em] font-light text-white px-2 py-1 rounded-md">
                  Current {/* Mark the last status as "Current" */}
                </span>
              )}
            </p>
            {/* Show status label */}
            <p className="text-[1.6em] text-gray-500">
              Status: {getTrackingStatusLabel(index, card.trackingHistory)}
            </p>
          </div>
        ))}
      </div>

      <div className="h-[0.1em] bg-[#e5e5e5b9] my-8"></div>
      {/* Address Details */}
      <div className="mt-6 ">
        <h3 className="font-semibold  text-[#1A253D] text-[2em] mb-1">
          Address Details
        </h3>

        <div className="text-[1.6em] text-gray-500">
          <div>{card.recipientName}</div>
          <div>{card.recipientPhoneNumber}</div>
          <p className="">
            {`${card.streetAddress}, ${card.city}, ${card.province}, ${card.postalCode}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
