const ROUTE_BASE = "/";
const ROUTE_DASHBOARD = "dashboard";
const ROUTE_MANAGE_ORDERS = "orders";
// const ROUTE_DEDICATED_ORDER = "dedicated-manage-orders";
const ROUTE_TEMPLATES = "templates";
const ROUTE_CATEGORIES = "categories";
const ROUTE_INVOICES = "invoices";
// const ROUTE_DEDICATED_INVOICE = "dedicated-invoices";
const ROUTE_USER_MANAGEMENT = "user-management";
const ROUTE_MANAGE_CONTENT = "manage-content";
const ROUTE_BLOGS = "blogs";
const ROUTE_PROMOS = "promos";
const ROUTE_SUBSCRIPTION_PACKS = "subscription-packs";
const ROUTE_MANAGE_FINANCE = "manage-finance";
const ROUTE_ORDERS = "orders";
const ROUTE_PRINTING = "printing";
const ROUTE_COURIER = "courier";
const ROUTE_ANALYTICS_REPORTS = "analytics-reports";
const ROUTE_PRINTING_SERVICE = "printing-service";
// const ROUTE_DEDICATED_PRINTING_SERVICE = "dedicated-printing-service";

const ROUTE_COURIER_SERVICE = "courier-service";
// const ROUTE_DEDICATED_COURIER_SERVICE = "dedicated-courier-service";
const ROUTE_LOGIN = "login";
const ROUTE_UNAUTHORIZED = "unauthorized";
const ROUTE_NO_MATCH = "*";

export const PATHS = {
  BASE: ROUTE_BASE,
  DASHBOARD: ROUTE_BASE + ROUTE_DASHBOARD,
  MANAGE_ORDERS: ROUTE_BASE + ROUTE_MANAGE_ORDERS,
  DEDICATED_ORDER: ROUTE_BASE + ROUTE_MANAGE_ORDERS + "/:orderID",
  DEDICATED_ORDER_PRINTER: ROUTE_BASE + "printing-orders/:orderID",
  TEMPLATES: ROUTE_BASE + ROUTE_TEMPLATES,
  CATEGORIES: ROUTE_BASE + ROUTE_CATEGORIES,
  INVOICES: ROUTE_BASE + ROUTE_INVOICES,
  DEDICATED_INVOICE: ROUTE_BASE + "invoice/:id",
  USER_MANAGEMENT: ROUTE_BASE + ROUTE_USER_MANAGEMENT,
  MANAGE_CONTENT: ROUTE_BASE + ROUTE_MANAGE_CONTENT,
  BLOGS: ROUTE_BASE + ROUTE_MANAGE_CONTENT + "/" + ROUTE_BLOGS,
  PROMOS: ROUTE_BASE + ROUTE_MANAGE_CONTENT + "/" + ROUTE_PROMOS,
  SUBSCRIPTION_PACKS:
    ROUTE_BASE + ROUTE_MANAGE_CONTENT + "/" + ROUTE_SUBSCRIPTION_PACKS,
  MANAGE_FINANCE: ROUTE_BASE + ROUTE_MANAGE_FINANCE,
  ORDERS: ROUTE_BASE + ROUTE_MANAGE_FINANCE + "/" + ROUTE_ORDERS,
  PRINTING: ROUTE_BASE + ROUTE_MANAGE_FINANCE + "/" + ROUTE_PRINTING,
  COURIER: ROUTE_BASE + ROUTE_MANAGE_FINANCE + "/" + ROUTE_COURIER,
  ANALYTICS_REPORTS: ROUTE_BASE + ROUTE_ANALYTICS_REPORTS,
  PRINTING_SERVICE: ROUTE_BASE + ROUTE_PRINTING_SERVICE,
  DEDICATED_PRINTING_SERVICE:
    ROUTE_BASE + ROUTE_PRINTING_SERVICE + "/:printerID",
  COURIER_SERVICE: ROUTE_BASE + ROUTE_COURIER_SERVICE,
  DEDICATED_COURIER_SERVICE: ROUTE_BASE + ROUTE_COURIER_SERVICE + "/:courierID",
  LOGIN: ROUTE_BASE + ROUTE_LOGIN,
  UNAUTHORIZED: ROUTE_BASE + ROUTE_UNAUTHORIZED,
  NO_MATCH: ROUTE_NO_MATCH,
};
