import React, { useEffect, useState } from "react";
import StatsCards from "../components/dashboard/StatsCards";
import PageHeader from "../reusable/PageHeader";
import actions from "../mock/actions.json";
import { useRole } from "../context/RoleContext";
import PendingOrders from "../components/dashboard/PendingOrders";
import AddButton from "../reusable/AddButton";
import OffCanvas from "../reusable/OffCanvas";
import AddTemplate from "../components/templattes/AddTemplate";
import TemplatesComponent from "../components/templattes/TemplatesComponent";
import useGetTemplates from "../hooks/templates/useGetTemplates";
import AddSuccessPopup from "../components/templattes/AddSuccessPopup";
import usePopUp from "../context/PopUpState";
function Dashboard() {
  const { isActionAllowed } = useRole();
  const { showPopup } = usePopUp();
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const {
    templatesFormatted,
    updateFilter: fetchTemplates,
    page,
    setPage,
    pageSize,
    setPageSize,
    templatesInfo,
  } = useGetTemplates(); // Fetch existing templates

  useEffect(() => {
    fetchTemplates(0, 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setIsOffCanvasOpen(true); // Open the off-canvas
  };

  const handleClose = () => {
    setIsOffCanvasOpen(false); // Close the off-canvas
  };

  // Function to add a new template to the table
  const handleAddTemplate = () => {
    setIsOffCanvasOpen(false); // Close off-canvas after save

    showPopup(<AddSuccessPopup />);
    fetchTemplates();

    // Optionally scroll to the bottom of the table to see the new template
    const tableElement = document.querySelector("#templateTable");
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div>
      <PageHeader
        path={"/ Dashboard"}
        headerText={"Dashboard"}
        actionComponent={
          <>
            {isActionAllowed(actions.CreateTemplate) && (
              <div>
                <AddButton text="New Template" width="" onClick={handleClick} />
              </div>
            )}
          </>
        }
      />

      <div className="mt-[2em]">
        <StatsCards />
      </div>
      <PendingOrders />
      {isActionAllowed(actions.ManageTemplates) && (
        <div className="mt-24">
          <TemplatesComponent
            tableHeader
            tableHeaderName={"Templates"}
            templates={templatesFormatted} // Pass the current templates list
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalEntries={templatesInfo?.totalEntries}
          />
        </div>
      )}
      {/* OffCanvas for Adding New Template */}
      <OffCanvas isOpen={isOffCanvasOpen} onClose={handleClose}>
        <AddTemplate onSave={handleAddTemplate} />
      </OffCanvas>
    </div>
  );
}

export default Dashboard;
