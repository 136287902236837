import React, { useContext, useState } from "react";
import { appContext as AppContext } from "./context";

const AppState = (props) => {
  const [isLoggedIn, setIsLoggedInState] = useState(
    sessionStorage.getItem("isAuthenticated") === "true"
  );

  const setIsLoggedIn = (val) => {
    setIsLoggedInState(val);
    sessionStorage.setItem("isAuthenticated", val);
  };
  return (
    <AppContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);

export default AppState;
