// src/context/RoleContext.jsx
import React, {
  createContext,
  useContext,
  // useEffect,
  useMemo,
  useState,
} from "react";
// eslint-disable-next-line
import actions from "../mock/actions.json";

// Create a context
const RoleContext = createContext();

// Create a provider component
export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  // Initialize the allowed actions (this could come from an API or be static)
  const [allowedAction] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26,
  ]);
  const allAct = [
    {
      role: "ROLE_ADMIN",
      allowedActions: [
        actions.ViewDashboard,
        actions.ManageOrders,
        actions.DedicatedOrder,
        actions.ManageTemplates,
        actions.CreateTemplate,
        actions.ManageCategories,
        actions.adminOrderStatuses,
        actions.ViewInvoice,
        actions.EditInvoiceStatus,
      ],
    },
    {
      role: "ROLE_PRINTER",
      allowedActions: [
        actions.ViewDashboard,
        actions.ManageOrders,
        actions.DedicatedOrderPrinter,
        actions.printerOrderStatuses,
      ],
    },
  ];
  const allowedActionsFinal = useMemo(
    () => allAct.find((act) => act.role === role)?.allowedActions || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [role]
  );

  // useEffect(() => {
  //   console.log(allowedActionsFinal);
  // }, [allowedActionsFinal]);

  const saveRole = (role) => {
    setRole(role);
    sessionStorage.setItem("role", role);
  };

  // Function to check if a specific action is allowed
  const isActionAllowed = (action) => {
    return allowedActionsFinal.includes(action);
  };

  return (
    <RoleContext.Provider
      value={{ allowedAction, isActionAllowed, role, saveRole }}
    >
      {children}
    </RoleContext.Provider>
  );
};

// Custom hook to use the RoleContext
export const useRole = () => useContext(RoleContext);
