import { useState } from "react";
// import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_ADD_CATEGORIES,
} from "../../constants/APIConstants";
import useApi from "../useApi";

const useAddCategory = () => {
  const [error, setError] = useState(null);
  const { makeApiCall, loading } = useApi();

  const addCategory = async (name) => {
    try {
      setError(null);

      const response = await makeApiCall({
        url: `${BASE_URL}${ENDPOINT_ADD_CATEGORIES}`,
        method: "POST",
        data: {
          name,
        },
      });

      if (response.data.success) {
        return true;
      } else {
        throw new Error(response.data.message || "Failed to add category");
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
      return false;
    }
  };

  return { addCategory, loading, error };
};

export default useAddCategory;
